import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CategoryState {
    selectedSuper: string | null;
    selectedMid: string | null;
    selectedSub: string | null;
    hoveredCategory: string | null;
}

const initialState: CategoryState = {
    selectedSuper: null,
    selectedMid: null,
    selectedSub: null,
    hoveredCategory: null,
};

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setSelectedCategories: (state, action: PayloadAction<Partial<CategoryState>>) => {
        return { ...state, ...action.payload };
        },
        setHoveredCategory: (state, action: PayloadAction<string | null>) => {
        state.hoveredCategory = action.payload;
        },
    },
});

export const { setSelectedCategories, setHoveredCategory } = categorySlice.actions;
export default categorySlice.reducer;