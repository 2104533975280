import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './routes';
import { App as AntApp } from 'antd';
import TokenExpirationModal from './components/ui/modal/TokenExpirationModal';

const App: React.FC = () => {
  return (
    <AntApp>
      <Router>
        <AuthProvider>
          <Routes />
          <TokenExpirationModal />
        </AuthProvider>
      </Router>
    </AntApp>
  );
};

export default App;