import CryptoJS from 'crypto-js';

const BASE_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

if (!BASE_SECRET_KEY) {
    throw new Error('REACT_APP_SECRET_KEY is not defined in environment variables');
}


const ENCRYPTION_KEY = CryptoJS.PBKDF2(BASE_SECRET_KEY, 'salt', {
    keySize: 256 / 32,
    iterations: 1000
}).toString();


export const encryptData = (data: any): string => {
    const stringifiedData = JSON.stringify(data); 
    return CryptoJS.AES.encrypt(stringifiedData, ENCRYPTION_KEY).toString();
};


export const decryptData = (ciphertext: string): any => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData); 
};