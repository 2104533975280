import React, { useState, useEffect, useMemo } from 'react';
import { Menu, Button, Tabs } from 'antd';
import type { MenuProps } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { categories, CategoryData, saveRecentEmoji, getRecentEmojis } from '../../../utils/emojiData';
import './CategoryMenu.css';

interface CategoryMenuProps {
  items: any[];
  currentSuperCategory: string;
  onCategorySelect?: (level: 'mid' | 'sub', category: string, currentSuperCategory?: string) => void;
  confirmedCategories: string[];
  onConfirmNewItem?: (category: string) => void;
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({
  items,
  currentSuperCategory,
  onCategorySelect,
  confirmedCategories,
  onConfirmNewItem
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isArchivePage = location.pathname.includes('/archive');
  
  const [current, setCurrent] = useState<string>('');
  const [hoveredMenu, setHoveredMenu] = useState<string | null>(null);
  const [iconStates, setIconStates] = useState<{ [key: string]: string }>({});
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    categories["최근"].emojis = getRecentEmojis();
  }, []);

  const filteredItems = useMemo(() => {
    return items.filter(item => item.supercategory === currentSuperCategory);
  }, [items, currentSuperCategory]);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const currentMidCategory = decodeURIComponent(pathParts[3] || '');
    const currentSubCategory = decodeURIComponent(pathParts[4] || '');

    if (currentSubCategory) {
      const parentItem = filteredItems.find(item => item.subcategory === currentSubCategory);
      if (parentItem) {
        setCurrent(`${parentItem.midcategory}-${currentSubCategory}`);
        setHoveredMenu(parentItem.midcategory);
      }
    } else if (currentMidCategory) {
      setCurrent(currentMidCategory);
      setHoveredMenu(null);
    }
  }, [location.pathname, filteredItems]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.emoji-dropdown') && !target.closest('.category-icon')) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const findParentMidCategory = (subCategory: string) => {
    const parentItem = filteredItems.find(item => item.subcategory === subCategory);
    return parentItem?.midcategory;
  };

  const handleSubCategoryClick = (subCategory: string) => {
    const realParentMid = findParentMidCategory(subCategory);
    
    if (!realParentMid) {
      console.error('Could not find parent midCategory for:', subCategory);
      return;
    }
  
    if (onCategorySelect) {
      onCategorySelect('sub', subCategory, currentSuperCategory);
    }
  
    const baseUrl = isArchivePage ? '/archive' : '/quickclip';
    const path = `${baseUrl}/${encodeURIComponent(currentSuperCategory)}/${encodeURIComponent(realParentMid)}/${encodeURIComponent(subCategory)}`;
    
    navigate(path);
    setCurrent(`${realParentMid}-${subCategory}`);
    setHoveredMenu(realParentMid);
  
    if (onConfirmNewItem && !confirmedCategories.includes(`${realParentMid}-${subCategory}`)) {
      onConfirmNewItem(`${realParentMid}-${subCategory}`);
    }
  };

  const handleMidCategoryClick = (midCategory: string) => {
    const baseUrl = isArchivePage ? '/archive' : '/quickclip';
    navigate(`${baseUrl}/${encodeURIComponent(currentSuperCategory)}/${encodeURIComponent(midCategory)}`);
    
    if (onCategorySelect) {
      onCategorySelect('mid', midCategory, currentSuperCategory);
    }
  };

  const handleIconClick = (midCategory: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdownVisible(true);
    setSelectedCategory(midCategory);
    setHoveredMenu(null);
  };

  const handleDropdownClose = () => {
    setDropdownVisible(false);
    setSelectedCategory(null);
  };

  const handleIconSelect = (emoji: string) => {
    if (selectedCategory) {
      setIconStates(prev => ({
        ...prev,
        [selectedCategory]: emoji
      }));
      saveRecentEmoji(emoji);
    }
    handleDropdownClose();
  };

  const renderEmojiTabs = () => {
    return (
      <Tabs
        defaultActiveKey="최근"
        items={Object.entries(categories).map(([categoryName, categoryData]) => ({
          key: categoryName,
          label: (
            <div className="emoji-tab-label">
              <span>{categoryData.emoji}</span>
              <span className="emoji-tab-text">{categoryName}</span>
            </div>
          ),
          children: (
            <div className="emoji-grid">
              {categoryData.emojis.map((emoji) => (
                <Button
                  key={emoji}
                  className="emoji-button"
                  onClick={() => handleIconSelect(emoji)}
                >
                  {emoji}
                </Button>
              ))}
            </div>
          ),
        }))}
      />
    );
  };

  const createMenuItems = (): MenuProps['items'] => {
    const midCategories = Array.from(new Set(
      filteredItems.map(item => item.midcategory)
    )).filter(Boolean);
    
    return midCategories.map(midCat => {
      const subCategories = Array.from(new Set(
        filteredItems
          .filter(item => item.midcategory === midCat)
          .map(item => item.subcategory)
      )).filter(Boolean);

      const subCategoryChildren = subCategories.length > 0 ? subCategories.map(subCat => ({
        key: `${midCat}-${subCat}`,
        label: subCat,
        onClick: (e: any) => {
          e.domEvent.stopPropagation();
          handleSubCategoryClick(subCat);
        }
      })) : undefined;

      const menuLabel = (
        <div style={{ display: 'flex', alignItems: 'center', minWidth: '150px' }}>
          <div className="category-icon-container">
            <span 
              className="category-icon"
              style={{ marginRight: '8px', fontSize: '16px', cursor: 'pointer' }}
              onClick={(e) => handleIconClick(midCat, e)}
            >
              {iconStates[midCat] || "📁"}
            </span>
            {selectedCategory === midCat && dropdownVisible && (
              <div className="emoji-dropdown">
                <div className="emoji-dropdown-content">
                  {renderEmojiTabs()}
                </div>
              </div>
            )}
          </div>
          <span onClick={() => handleMidCategoryClick(midCat)} style={{ cursor: 'pointer' }}>
            {midCat}
          </span>
        </div>
      );

      return {
        key: midCat,
        label: menuLabel,
        children: subCategoryChildren,
        onMouseEnter: () => {
          if (!dropdownVisible) {
            setHoveredMenu(midCat);
          }
        },
        onMouseLeave: () => {
          if (!dropdownVisible && !location.pathname.split('/')[4]) {
            setHoveredMenu(null);
          }
        }
      };
    });
  };

  return (
    <div className="category-menu-container">
      <Menu
        mode="horizontal"
        selectedKeys={[current]}
        defaultSelectedKeys={[current]}
        defaultOpenKeys={hoveredMenu ? [hoveredMenu] : []}
        items={createMenuItems()}
        style={{ 
          width: '100%', 
          marginBottom: '20px',
          whiteSpace: 'nowrap',
          overflow: 'visible'
        }}
        openKeys={dropdownVisible ? [] : (hoveredMenu ? [hoveredMenu] : [])}
        onOpenChange={(keys) => {
          if (!dropdownVisible) {
            const lastKey = keys[keys.length - 1];
            setHoveredMenu(lastKey || null);
          }
        }}
        triggerSubMenuAction="hover"
      />
    </div>
  );
};

export default CategoryMenu;