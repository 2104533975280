import React, { useEffect, useState } from 'react';
import { Routes as RouterRoutes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import HomePage from '../pages/HomePage';
import SignInPage from '../pages/SignInPage';
import SignUpPage from '../pages/SignUpPage';
import AppLayout from '../components/layout/AppLayout';
import SearchResults from '../pages/SearchResults';
import BinPage from '../pages/TrashPage';
import CategoryPage from '../pages/CategoryPage';
import BookmarkPage from '../pages/BookmarkPage';
import PieceBodyPage from '../pages/PieceBodyPage';
import PrivacyPolicy from '../pages/PrivacyPolicy';


const Routes: React.FC = () => {
  const { checkAuth, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);

  // 업로드 데이터를 관리하는 상태 추가
  const [uploads, setUploads] = useState<any[]>([]);

  const publicPaths = ['/', '/signup', '/PrivacyPolicy'];

  useEffect(() => {
    const check = async () => {
      await checkAuth();
      setIsChecking(false);
    };
    check();
  }, [checkAuth]);

  useEffect(() => {
    if (!isChecking && 
        !isAuthenticated && 
        !publicPaths.includes(location.pathname)
    ) {
      navigate('/signin');
    }
  }, [isChecking, isAuthenticated, navigate, location.pathname]);

  // 업로드 완료 후 호출될 함수
  const handleUpload = (newUpload: any) => {
    setUploads((prevUploads) => [...prevUploads, newUpload]);
  };

  if (isChecking) {
    return <div>로딩 중...</div>; 
  }

  return (
    <RouterRoutes>
      {/* '/' 경로에서만 AppLayout과 onUpload 전달 */}
      <Route 
        path="/" 
        element={
          isAuthenticated 
            ? <AppLayout onUpload={handleUpload}><HomePage /></AppLayout>  // 인증된 경우 AppLayout 적용
            : <HomePage />                        // 인증되지 않은 경우 AppLayout 없이 HomePage
        } 
      />
      
      {/* 다른 경로에서는 AppLayout에 onUpload 전달하지 않음 */}
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/search" element={<AppLayout><SearchResults /></AppLayout>} />
      
      <Route path="/quickclip" element={<AppLayout><CategoryPage /></AppLayout>} />
      <Route path="/quickclip/:superCategory" element={<AppLayout><CategoryPage /></AppLayout>} />
      <Route path="/quickclip/:superCategory/:midcategory" element={<AppLayout><CategoryPage /></AppLayout>} />
      <Route path="/quickclip/:superCategory/:midcategory/:subCategory" element={<AppLayout><CategoryPage /></AppLayout>} />
      
      <Route path="/quickclip/:superCategory/:midcategory/:subCategory/:title" element={<AppLayout><PieceBodyPage /></AppLayout>} />
      <Route path="/:title" element={<AppLayout><PieceBodyPage /></AppLayout>} />

      <Route path="/archive" element={<AppLayout><CategoryPage /></AppLayout>} />
      <Route path="/archive/:superCategory" element={<AppLayout><CategoryPage /></AppLayout>} />
      <Route path="/archive/:superCategory/:midcategory" element={<AppLayout><CategoryPage /></AppLayout>} />
      <Route path="/archive/:superCategory/:midcategory/:subCategory" element={<AppLayout><CategoryPage /></AppLayout>} />
      
      <Route path="/trash" element={<AppLayout><BinPage /></AppLayout>} />
      <Route path="/bookmark" element={<AppLayout><BookmarkPage /></AppLayout>} />
    </RouterRoutes>
  );
};

export default Routes;

