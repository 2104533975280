import React, { useState } from 'react';
import { Card, Tooltip, Dropdown, Menu, message, notification, Button, Popover, Badge } from 'antd';
import { EyeOutlined, StarOutlined, StarFilled, MoreOutlined, DeleteOutlined, EditOutlined, DownloadOutlined, 
  FileExcelOutlined, FileWordOutlined, FilePdfOutlined, FileImageOutlined, FileZipOutlined, 
  FileTextOutlined, FilePptOutlined, FileUnknownOutlined, FileOutlined } from '@ant-design/icons';
import { File } from '../../../types/ChildObj';
import { setDeletedAt, toggleBookmark, updateTitle } from '../../../api/ChildObj';
import { useNavigate } from 'react-router-dom';
// import { downloadFile } from '../../../utils/downloadFile';
import EditModal from '../../ui/modal/EditModal';
import { generatePresignedUrl } from '../../../api/File';

const { Meta } = Card;

interface FileCardProps {
  file: File;
  onViewCountChange: (id: string, count: number) => void;
  onBookmarkToggle: (id: string, bookmarked: boolean) => void;
  onDelete: (id: string) => void;
  onEdit: (id: string, newTitle: string) => void;
}

const FileCard: React.FC<FileCardProps> = ({ 
  file, 
  onViewCountChange, 
  onBookmarkToggle, 
  onDelete, 
  onEdit,
}) => {
  const navigate = useNavigate();
  const [viewCount, setViewCount] = useState(file.views || 0);
  const [isBookmarked, setIsBookmarked] = useState(file.bookmark || false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const cardStyle = {
    position: 'relative' as 'relative',
    overflow: 'hidden',
  };

  const fileIconContainerStyle = {
    position: 'relative' as 'relative',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f0f0f0',
  };

  const fileIconStyle = {
    fontSize: '64px',
  };

  const badgeStyle = {
    backgroundColor: '#87d068', // 파일용 색상
    fontSize: '12px',
    padding: '0 8px',
    height: '22px',
    lineHeight: '22px',
    borderRadius: '11px',
    flexShrink: 0
  };

  const iconStyle = {
    fontSize: '20px',
    cursor: 'pointer',
  };

  const iconContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '5px',
    marginBottom: '10px',
    gap: '15px',
  };

  const summaryStyle = {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical' as 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  };

  const fullSummaryStyle = {
    maxWidth: '300px',
    maxHeight: '200px',
    overflow: 'auto',
    padding: '10px',
  };

  const getFileIcon = (fileType: string) => {
    const iconProps = { style: { ...fileIconStyle } };
    const lowerCaseType = fileType.toLowerCase();
 
    if (lowerCaseType.includes('excel') || lowerCaseType.includes('spreadsheet') || lowerCaseType.endsWith('xlsx') || lowerCaseType.endsWith('xls')) {
      return <FileExcelOutlined {...iconProps} style={{ ...iconProps.style, color: '#217346' }} />;
    } else if (lowerCaseType.includes('word') || lowerCaseType.includes('document') || lowerCaseType.endsWith('docx') || lowerCaseType.endsWith('doc')) {
      return <FileWordOutlined {...iconProps} style={{ ...iconProps.style, color: '#2b579a' }} />;
    } else if (lowerCaseType.includes('pdf')) {
      return <FilePdfOutlined {...iconProps} style={{ ...iconProps.style, color: '#f40f02' }} />;
    } else if (lowerCaseType.includes('image') || ['jpg', 'jpeg', 'png', 'gif'].some(ext => lowerCaseType.endsWith(ext))) {
      return <FileImageOutlined {...iconProps} style={{ ...iconProps.style, color: '#36cfc9' }} />;
    } else if (lowerCaseType.includes('zip') || lowerCaseType.includes('compressed') || ['zip', 'rar', '7z'].some(ext => lowerCaseType.endsWith(ext))) {
      return <FileZipOutlined {...iconProps} style={{ ...iconProps.style, color: '#ffd591' }} />;
    } else if (lowerCaseType.includes('text') || lowerCaseType.endsWith('txt')) {
      return <FileTextOutlined {...iconProps} style={{ ...iconProps.style, color: '#8c8c8c' }} />;
    } else if (lowerCaseType.includes('powerpoint') || lowerCaseType.includes('presentation') || lowerCaseType.endsWith('ppt') || lowerCaseType.endsWith('pptx')) {
      return <FilePptOutlined {...iconProps} style={{ ...iconProps.style, color: '#d04423' }} />;
    } else if (['hwp', 'hwpx', 'hwpml'].some(ext => lowerCaseType.endsWith(ext))) {
      return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <FileOutlined {...iconProps} style={{ ...iconProps.style, color: '#10abeb' }} />
          <span style={{ 
            position: 'absolute', 
            top: '60%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            fontSize: '2.5em',
            fontWeight: 600,
            color: '#10abeb'
          }}>
            H
          </span>
        </div>
      );
    } else {
      return <FileUnknownOutlined {...iconProps} style={{ ...iconProps.style, color: '#8c8c8c' }} />;
    }
  };

  const handleView = () => {
    if (file._id) {
      const newCount = viewCount + 1;
      setViewCount(newCount);
      onViewCountChange(file._id, newCount);
    }
  };

  const handleBookmarkToggle = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (file._id) {
        try {
            const newBookmarkStatus = await toggleBookmark(file);
            setIsBookmarked(newBookmarkStatus); 
            onBookmarkToggle(file._id, newBookmarkStatus);
        } catch (error: any) {
            // console.error('북마크 토글 중 오류 발생:', error);
        }
    }
  };

  const handleDelete = async () => {
    if (file._id && typeof file._id === 'string') {
      const success = await setDeletedAt(file);
      if (success) {
        
        notification.open({
          message: '파일이 삭제되었습니다',
          description: '휴지통에 30일 동안 보관되며, 그 후에 영구 삭제됩니다.',
          btn: (
            <Button type="primary" size="small" onClick={() => navigate('/trash')}>
              휴지통 바로가기
            </Button>
          ),
        });

        onDelete(file._id);
      } else {
        message.error('휴지통 이통을 실패했습니다. 다시 시도해 주세요.');
      }
    } else {
      message.error('유효하지 않은 ID입니다.');
    }
  };

  const handleDownload = async () => {
    if (file._id) {
      try {
        const { presignedUrl, fileType, title } = await generatePresignedUrl(file._id);
        
        // iframe을 사용한 다운로드
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = presignedUrl;
        document.body.appendChild(iframe);
        
        message.success('파일 다운로드를 시작합니다.');
        
        // 잠시 후 iframe 제거
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 2000);
      } catch (error) {
        console.error('Download failed:', error);
        message.error('파일 다운로드에 실패했습니다. 오류: ' + (error as Error).message);
      }
    }
  };

  const handleEdit = () => {
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleEditModalSave = async (newTitle: string) => {
    if (file._id) {
      try {
        const success = await updateTitle(file, newTitle);
        if (success) {
          onEdit(file._id, newTitle);
          message.success('제목이 수정되었습니다.');
        } else {
          message.error('제목 수정에 실패했습니다.');
        }
      } catch (error) {
        // console.error('제목 수정 중 오류 발생:', error);
        message.error('제목 수정 중 오류가 발생했습니다.');
      }
    }
    setIsEditModalVisible(false);
  };

  const SummaryContent = () => (
    <Popover
      content={<div style={fullSummaryStyle}>{file.summary}</div>}
      title="전체 요약"
      trigger="hover"
      mouseEnterDelay={0.5}
    >
      <div style={summaryStyle}>{file.summary}</div>
    </Popover>
  );

  const menu = (
    <Menu>
      <Menu.Item key="edit" onClick={handleEdit} icon={<EditOutlined />}>
        수정
      </Menu.Item>
      <Menu.Item key="delete" onClick={handleDelete} icon={<DeleteOutlined />}>
        삭제
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Card
        hoverable
        style={cardStyle}
        cover={
          <div style={fileIconContainerStyle}>
            {getFileIcon(file.fileType)}
            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <MoreOutlined style={iconStyle} onClick={(e) => e.stopPropagation()} />
              </Dropdown>
            </div>
          </div>
        }
      >
        <div style={iconContainerStyle}>
          <Tooltip title={viewCount === 0 ? '읽지 않음' : `${viewCount}회 조회`}>
            <EyeOutlined style={iconStyle} onClick={handleView} />
            {viewCount > 0 && <span style={{ marginLeft: '5px' }}>{viewCount}</span>}
          </Tooltip>
          <Tooltip title={isBookmarked ? '북마크 해제' : '북마크'}>
            {isBookmarked ? (
              <StarFilled onClick={handleBookmarkToggle} style={{ ...iconStyle, color: '#fadb14' }} />
            ) : (
              <StarOutlined onClick={handleBookmarkToggle} style={iconStyle} />
            )}
          </Tooltip>
          <Tooltip title="다운로드">
            <DownloadOutlined style={iconStyle} onClick={handleDownload} />
          </Tooltip>
        </div>

        <Meta 
          title={
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginBottom: '8px' }}>
              <Badge 
                count="File"
                style={{
                  backgroundColor: '#87d068',
                  fontSize: '12px',
                  padding: '0 8px',
                  height: '22px',
                  lineHeight: '22px',
                  borderRadius: '11px',
                  flexShrink: 0
                }}
              />
              <Popover 
                content={file.title} 
                trigger="hover"
                placement="top"
                mouseEnterDelay={1.5}
              >
                <span style={{ 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}>
                  {file.title}
                </span>
              </Popover>
            </div>
          }
          description={
            <div>
              <SummaryContent />
              <p style={{ marginTop: '8px', fontSize: '12px', color: '#8c8c8c' }}>
                파일 형식: {file.fileType}
              </p>
            </div>
          } 
        />
      </Card>
      <EditModal
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        onSave={handleEditModalSave}
        initialTitle={file.title}
        titleOnly={true}
      />
    </>
  );
};

export default FileCard;
