import React, { CSSProperties } from 'react';
import { Layout, Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import Search from '../ui/Search/Search';

interface HeaderProps {
  customStyle?: CSSProperties;
}

const { Header: AntHeader } = Layout;

const Header: React.FC<HeaderProps> = ({ customStyle }) => {
  const location = useLocation();

  if (location.pathname === '/') {
    return null;
  }

  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const decodeURLComponent = (component: string) => {
    try {
      return decodeURIComponent(component.replace(/\+/g, ' '));
    } catch {
      return component;
    }
  };

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
      </Link>
    </Breadcrumb.Item>,
  ];

  let currentPath = '';
  pathSnippets.forEach((snippet, index) => {
    currentPath += `/${snippet}`;
    const decodedName = decodeURLComponent(snippet);

    if (currentPath.startsWith('/quickclip')) {
      if (index === 0) {
        // QuickClip
        breadcrumbItems.push(
          <Breadcrumb.Item key={currentPath}>
            <Link to={currentPath}>{decodedName}</Link>
          </Breadcrumb.Item>
        );
      } else if (index === 1 || index === 2) {
        // superCategory, midCategory
        breadcrumbItems.push(
          <Breadcrumb.Item key={currentPath}>
            <Link to={currentPath}>{decodedName}</Link>
          </Breadcrumb.Item>
        );
      } else if (index === 3 && decodedName !== 'no-subcategory' && pathSnippets.length > 4) {
        // subCategory (if exists and is not 'no-subcategory')
        breadcrumbItems.push(
          <Breadcrumb.Item key={currentPath}>
            <Link to={currentPath}>{decodedName}</Link>
          </Breadcrumb.Item>
        );
      } else if (index === pathSnippets.length - 1) {
        // title (last item)
        breadcrumbItems.push(
          <Breadcrumb.Item key={currentPath}>
            {decodedName}
          </Breadcrumb.Item>
        );
      }
    } else {
      // 다른 경로에 대한 기본 처리
      breadcrumbItems.push(
        <Breadcrumb.Item key={currentPath}>
          <Link to={currentPath}>{decodedName}</Link>
        </Breadcrumb.Item>
      );
    }
  });

  const headerStyle: CSSProperties = {
    background: '#fff',
    padding: '0 20px',
    height: '64px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...customStyle,
  };

  return (
    <AntHeader style={headerStyle}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        {breadcrumbItems}
      </Breadcrumb>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Search size="small" />
      </div>
    </AntHeader>
  );
};

export default Header;