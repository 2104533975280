import axiosInstance from '../utils/axiosInstance';
import Cookies from 'js-cookie';
import axios, { AxiosError } from 'axios';
import qs from 'qs';
import {
    SigninResponse,
    SignoutResponse,
    SignupRequest,
    SignupResponse,
} from '../types/auth';
import { logTokenExpiration } from '../utils/axiosInstance'

declare module 'axios' {
    export interface AxiosRequestConfig {
        _isLoginRequest?: boolean;
    }
}

export const signin = async (username: string, password: string): Promise<SigninResponse> => {
    try {
        const data = { username, password };
        const formData = qs.stringify(data);

        const response = await axiosInstance.post<SigninResponse>('/api/v1/auth/signin', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            },
            _isLoginRequest: true
        });
        
        const { access_token, refresh_token, token_type } = response.data;
        
        Cookies.set('accessToken', access_token, { secure: true, sameSite: 'strict' });
        Cookies.set('refreshToken', refresh_token, { secure: true, sameSite: 'strict' });
        Cookies.set('tokenType', token_type, { secure: true, sameSite: 'strict' });
        
        // 로그인 후 만료 시간 로그 출력
        logTokenExpiration();

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 422) {
                const errorDetails = error.response.data.detail;
                if (Array.isArray(errorDetails) && errorDetails.length > 0) {
                    throw new Error(errorDetails[0].msg || '입력한 정보를 다시 확인해주세요.');
                } else {
                    throw new Error('입력한 정보를 다시 확인해주세요.');
                }
            } else if (error.response.status === 401 || error.response.status === 404) {
                throw new Error('이메일 또는 비밀번호가 올바르지 않습니다.');
            } else {
                throw new Error('로그인 중 오류가 발생했습니다.');
            }
        } else {
            throw new Error('알 수 없는 오류가 발생했습니다.');
        }
    }
};

export const signout = async (): Promise<SignoutResponse> => {
    try {
        const accessToken = Cookies.get('accessToken');
        
        if (!accessToken) {
            // console.warn('No access token found');
            return { result: false };
        }
    
        const response = await axiosInstance.post<SignoutResponse>(
            '/api/v1/auth/signout',
            { accessToken },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
    
        if (response.data.result) {
            Cookies.remove('accessToken');
            Cookies.remove('refreshToken');
            Cookies.remove('tokenType');
        } 
    
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
        }
        throw error;
    }
};

// Type guard for Axios errors
function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError === true;
}

export const signup = async (email: string, password: string): Promise<SignupResponse> => {
    try {
        const data: SignupRequest = { email, password };
        const response = await axiosInstance.post<SignupResponse>('/api/v1/user/signup',
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                switch (axiosError.response.status) {
                    case 422:
                        throw new Error('유효한 이메일 형식이 아닙니다.');
                    case 409:
                        throw new Error('이미 존재하는 이메일입니다.');
                    default:
                        throw new Error('회원가입 중 오류가 발생했습니다.');
                }
            }
        }
        throw new Error('알 수 없는 오류가 발생했습니다.');
    }
};