import axiosInstance from "../utils/axiosInstance";
import { Article } from "../types/Article";

// 링크 업로드 함수
export const uploadArticle = async (linkUrl: string): Promise<Article> => {
    try {
        const response = await axiosInstance.post<Article>('/api/v1/articles', {
            linkUrl,
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response.data;  
    } catch (error) {
        // console.error('링크 업로드 실패:', error);
        throw error;
    }
}


