import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AutoComplete, Input } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { useNavigate } from 'react-router-dom';
import { searchAPI, searchAutocompleteAPI } from '../../../api/search';
import debounce from 'lodash/debounce';

export interface SearchProps {
    size?: 'small' | 'large';
    style?: React.CSSProperties;
}

interface AutocompleteItem {
    value: string;
    label: string;
}

const Search: React.FC<SearchProps> = ({ size = 'large', style }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteItem[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [responsiveSize, setResponsiveSize] = useState<'small' | 'large'>(size);
    const inputRef = useRef<InputRef>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setResponsiveSize('small');
            } else {
                setResponsiveSize(size);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 사이즈 설정
        return () => window.removeEventListener('resize', handleResize);
    }, [size]);

    const handleSearch = useCallback(async (value: string) => {
        if (value.trim()) {
            try {
                await searchAPI(
                    value.trim(),
                    'all',
                    {
                        title: true,
                        keywords: true,
                        body: true,
                        summary: true,
                        page: 1,
                        size: 10
                    }
                );
                navigate(`/search?q=${encodeURIComponent(value.trim())}`);
            } catch (error) {
                // console.error('Search failed:', error);
            }
        }
    }, [navigate]);

    const fetchAutocomplete = useCallback(debounce(async (value: string) => {
        if (value.trim()) {
            try {
                const result = await searchAutocompleteAPI(value.trim(), 'all');
                setAutocompleteOptions(result.map(item => ({ value: item.title, label: item.title })));
            } catch (error) {
                // console.error('Autocomplete failed:', error);
            }
        } else {
            setAutocompleteOptions([]);
        }
    }, 300), []);

    const handleInputChange = (value: string) => {
        setSearchValue(value);
        setSelectedValue('');
        fetchAutocomplete(value);
    };

    const handleSelect = (value: string) => {
        setSelectedValue(value);
        setSearchValue(value);
        handleSearch(value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch(selectedValue || searchValue);
        }
    };

    const getInputStyle = useCallback(() => {
        return { 
            width: responsiveSize === 'small' ? '300px' : '700px', 
            borderRadius: responsiveSize === 'small' ? '16px' : '20px',
            height: responsiveSize === 'small' ? '32px' : '50px',
            fontSize: responsiveSize === 'small' ? '14px' : '16px',
            backgroundColor: 'transparent',
            border: '1px solid #d9d9d9',
            color: 'var(--text-color)', 
        };
    }, [responsiveSize]);

    return (
        <AutoComplete
            popupClassName={`certain-category-search-dropdown ${responsiveSize === 'small' ? 'small' : ''}`}
            popupMatchSelectWidth={responsiveSize === 'small' ? 300 : 700}
            style={{ ...(responsiveSize === 'small' ? { width: 300 } : { width: 700 }), ...style }}
            options={autocompleteOptions}
            value={searchValue}
            onSelect={handleSelect}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
        >
            <Input
                ref={inputRef}
                size={responsiveSize}
                placeholder=" 제목이나 키워드를 입력하세요."
                style={getInputStyle()}
                allowClear={{ 
                    clearIcon: <CloseOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} /> 
                }}
                suffix={
                    <SearchOutlined
                        style={{ 
                            cursor: 'pointer',
                            marginLeft: '8px' // 클리어 버튼과의 간격 조정
                        }}
                        onClick={() => handleSearch(selectedValue || searchValue)}
                    />
                }
            />
        </AutoComplete>
    );
};

export default Search;