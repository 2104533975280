import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { List, Pagination, Spin, Empty, Divider, Tag, message } from 'antd';
import { 
  FileExcelOutlined, 
  FileWordOutlined, 
  FilePdfOutlined,
  FileImageOutlined,
  FileZipOutlined,
  FileTextOutlined,
  FilePptOutlined,
  FileOutlined,
  FileUnknownOutlined
} from '@ant-design/icons';
import { searchAPI } from '../api/search';
import { SearchResponse, SearchResultItem } from '../types/search';
import { PinIdBy } from '../api/ChildObj';
import { downloadFile } from '../utils/downloadFile';

const SearchResults: React.FC = () => {
  const [searchResults, setSearchResults] = useState<SearchResultItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q') || '';

  const pageSize = 10;

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!query) return;

      setLoading(true);
      try {
        const result: SearchResponse = await searchAPI(query, 'all', {
          title: true,
          keywords: true,
          body: true,
          summary: true,
          page: currentPage,
          size: pageSize
        });
        setSearchResults(result.items);
        setTotalResults(result.total);
      } catch (error) {
        // console.error('Failed to fetch search results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [query, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0); // 페이지 변경 시 스크롤을 맨 위로 이동
  };

  const handleTitleClick = async (item: SearchResultItem) => {
    if (item._class_id === 'ObjDoc.Article' && item.linkUrl) {
      window.open(item.linkUrl, '_blank', 'noopener,noreferrer');
    } else if (item._class_id === 'ObjDoc.Piece') {
      try {
        const fullData = await PinIdBy(item._id);
        navigate(`/${encodeURIComponent(item.title)}`, {
          state: { 
            piece: {
              ...item,
              ...fullData,
              createdAt: item.createdAt || new Date().toISOString(),
              keywords: item.keywords || [],
              linkUrl: item.linkUrl || '',
              title: item.title,
            }
          }
        });
      } catch (error) {
        console.error('Failed to fetch full piece data:', error);
        message.error('데이터를 불러오는데 실패했습니다.');
      }
    } else if (item._class_id === 'ObjDoc.File') {
      try {
        await downloadFile(item._id);
      } catch (error) {
        console.error('Failed to download file:', error);
        message.error('파일 다운로드에 실패했습니다.');
      }
    }
  };

  const renderTitle = (item: SearchResultItem) => {
    return (
      <a onClick={(e) => {
        e.preventDefault();
        handleTitleClick(item);
      }} 
      style={{ cursor: 'pointer' }}
      >
        {item.title}
      </a>
    );
  };

  const fileIconStyle: React.CSSProperties = {
    fontSize: '80px'
  };

  const tagStyle: React.CSSProperties = {
    fontSize: '14px',
    padding: '6px 16px',
    borderRadius: '20px',
    marginRight: '10px',
    marginBottom: '8px',
    backgroundColor: '#f5f5f5',
    border: 'none',
    color: '#595959'
  };

  const imageStyle: React.CSSProperties = {
    width: '273px',
    height: '153px',
    objectFit: 'cover',
    objectPosition: 'center',
  };

  const getFileIcon = (fileType: string) => {
    const iconProps = { style: { ...fileIconStyle } };
    const lowerCaseType = fileType.toLowerCase();

    if (lowerCaseType.includes('excel') || lowerCaseType.includes('spreadsheet') || lowerCaseType.endsWith('xlsx') || lowerCaseType.endsWith('xls')) {
      return <FileExcelOutlined {...iconProps} style={{ ...iconProps.style, color: '#217346' }} />;
    } else if (lowerCaseType.includes('word') || lowerCaseType.includes('document') || lowerCaseType.endsWith('docx') || lowerCaseType.endsWith('doc')) {
      return <FileWordOutlined {...iconProps} style={{ ...iconProps.style, color: '#2b579a' }} />;
    } else if (lowerCaseType.includes('pdf')) {
      return <FilePdfOutlined {...iconProps} style={{ ...iconProps.style, color: '#f40f02' }} />;
    } else if (lowerCaseType.includes('image') || ['jpg', 'jpeg', 'png', 'gif'].some(ext => lowerCaseType.endsWith(ext))) {
      return <FileImageOutlined {...iconProps} style={{ ...iconProps.style, color: '#36cfc9' }} />;
    } else if (lowerCaseType.includes('zip') || lowerCaseType.includes('compressed') || ['zip', 'rar', '7z'].some(ext => lowerCaseType.endsWith(ext))) {
      return <FileZipOutlined {...iconProps} style={{ ...iconProps.style, color: '#ffd591' }} />;
    } else if (lowerCaseType.includes('text') || lowerCaseType.endsWith('txt')) {
      return <FileTextOutlined {...iconProps} style={{ ...iconProps.style, color: '#8c8c8c' }} />;
    } else if (lowerCaseType.includes('powerpoint') || lowerCaseType.includes('presentation') || lowerCaseType.endsWith('ppt') || lowerCaseType.endsWith('pptx')) {
      return <FilePptOutlined {...iconProps} style={{ ...iconProps.style, color: '#d04423' }} />;
    } else if (['hwp', 'hwpx', 'hwpml'].some(ext => lowerCaseType.endsWith(ext))) {
      return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <FileOutlined {...iconProps} style={{ ...iconProps.style, color: '#10abeb' }} />
          <span style={{ 
            position: 'absolute', 
            top: '60%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            fontSize: '2.5em',
            fontWeight: 600,
            color: '#10abeb'
          }}>
            H
          </span>
        </div>
      );
    } else {
      return <FileUnknownOutlined {...iconProps} style={{ ...iconProps.style, color: '#8c8c8c' }} />;
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>"{query}"에 대한 검색 결과입니다.</h1>
      <Divider />
      {loading ? (
        <Spin size="large" />
      ) : searchResults.length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <List
            style={{ width: '100%' }}
            itemLayout="vertical"
            size="large"
            dataSource={searchResults}
            renderItem={(item: SearchResultItem) => (
              <List.Item
                key={item._id}
                extra={
                  <div style={{ width: '273px', height: '153px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f5f5f5' }}>
                    {item.thumbnail ? (
                      <img
                        referrerPolicy="no-referrer"
                        style={imageStyle}
                        alt="thumbnail"
                        src={item.thumbnail}
                      />
                    ) : (
                      getFileIcon(item.fileType || '')
                    )}
                  </div>
                }
              >
                <List.Item.Meta
                  title={renderTitle(item)}
                  description={
                    <div>
                      <div style={{ whiteSpace: 'pre-wrap', marginBottom: '12px' }}>
                        {item.summary}
                      </div>
                      {item.keywords && item.keywords.length > 0 && (
                        <div style={{ marginTop: '12px', display: 'flex', flexWrap: 'wrap' }}>
                          {item.keywords.map((keyword, index) => (
                            <Tag key={index} style={tagStyle}>
                              #{keyword}
                            </Tag>
                          ))}
                        </div>
                      )}
                    </div>
                  }
                />
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {item.body}
                </div>
              </List.Item>
            )}
          />
          <Pagination
            current={currentPage}
            total={totalResults}
            pageSize={pageSize}
            onChange={handlePageChange}
            style={{ 
              marginTop: '20px',
              marginBottom: '40px',
              display: 'flex',
              justifyContent: 'center'
            }}
          />
        </div>
      ) : (
        <Empty description="검색 결과가 없습니다" />
      )}
    </div>
  );
};

export default SearchResults;