import axiosInstance from '../utils/axiosInstance';
import { ObjType, ObjDoc, getObjectType } from '../types/ChildObj';
import { AutocompleteItem } from '../types/search';
import axios from 'axios';


// 조회수 증가 API
export const increaseViews = async (objDoc: ObjDoc): Promise<number | null> => {
    if (!objDoc._id) {
        // console.error('Invalid objDoc: missing _id');
        return null;
    }

    const objType = getObjectType(objDoc);

    try {
        const response = await axiosInstance.post(`/api/v1/${objType}s/views`, null, {
            params: { obj_type: objType, obj_doc_id: objDoc._id },
        });

        if (response.data.result === false) {
            // console.log(`Views not increased for ${objType} with ID: ${objDoc._id}`);
            return null;
        }

        const viewCount = parseInt(response.data.result, 10);
        if (isNaN(viewCount)) {
            // console.error('Unexpected response format: view count is not a number');
            return null;
        }

        return viewCount;
    } catch (error) {
        if (error instanceof Error) {
            // console.error(`Error occurred while increasing views for ${objType} with ID: ${objDoc._id}:`, error.message);
        } else {
            // console.error(`Unknown error occurred while increasing views for ${objType} with ID: ${objDoc._id}`);
        }
        return null;
    }
};

// 북마크 토글 API
export const toggleBookmark = async (objDoc: ObjDoc): Promise<boolean> => {
    if (!objDoc._id) {
        // console.error('Invalid objDoc: missing _id');
        return false;
    }

    const objType = getObjectType(objDoc);

    try {
        const response = await axiosInstance.post(`/api/v1/objs/${objDoc._id}/bookmark`, null, {
            params: { obj_type: objType, obj_doc_id: objDoc._id },
        });

        if (typeof response.data.bookmark !== 'boolean') {
            return false;
        }

        return response.data.bookmark;
    } catch (error) {
        if (error instanceof Error) {
            // console.error(`Error occurred while toggling bookmark for ${objType} with ID: ${objDoc._id}:`, error.message);
        } else {
            // console.error(`Unknown error occurred while toggling bookmark for ${objType} with ID: ${objDoc._id}`);
        }
        return false;
    }
};


// 영구 삭제
export const deleteChildObj = async (objDoc: ObjDoc): Promise<boolean> => {
    try {
        if (!objDoc._id) {
            throw new Error('Invalid objDoc: missing _id');
        }

        const objType = getObjectType(objDoc);
        const url = `/api/v1/objs/${objDoc._id}/`;

        // console.log('Sending DELETE request to:', url);
        // console.log('Request headers:', axiosInstance.defaults.headers);

        await axiosInstance.delete(url);

        // console.log('Response:', response);

        return true;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // console.error('삭제 처리 중 오류 발생:', error);
        }
        return false;
    }
};


// 휴지통 이동
export const setDeletedAt = async (objDoc: ObjDoc): Promise<boolean> => {
    if (!objDoc._id) {
        return false;
    }

    try {
        const response = await axiosInstance.patch(`/api/v1/objs/${objDoc._id}/set-deleted-at`);
        return true;
    } catch (error) {
        return false;
    }
};

// 복구
export const restoreChildObj = async (objDoc: ObjDoc): Promise<ObjDoc | null> => {
    try {
        if (!objDoc._id) {
            throw new Error('Invalid objDoc: missing _id');
        }

        const objType = getObjectType(objDoc);

        const response = await axiosInstance.patch(`/api/v1/objs/${objDoc._id}/unset-deleted-at`, null, {
            params: { 
                obj_type: objType, 
                obj_doc_id: objDoc._id 
            },
        });

        // 서버 응답 데이터를 processServerData를 통해 처리
        return processServerData(response.data);
    } catch (error) {
        // console.error('복구 처리 중 오류 발생:', error);
        return null;
    }
};


// 휴지통 조회
export const getFromBin = async (page: number = 1, size: number = 10): Promise<{ items: ObjDoc[], total: number }> => {
    try {
        const response = await axiosInstance.get('/api/v1/objs/bin', {
            params: { page, size },
        });
        
        return {
            items: response.data.items as ObjDoc[],
            total: response.data.total
        };
    } catch (error) {
        // console.error('휴지통 데이터를 가져오는 중 오류 발생:', error);
        throw error;
    }
};

// 타이틀 수정
export const updateTitle = async (objDoc: ObjDoc, newTitle: string): Promise<boolean> => {
    try {
        if (!objDoc._id) {
            throw new Error('Invalid objDoc: missing _id');
        }
    
        const objType = getObjectType(objDoc);
        const url = `/api/v1/${objType}s/title`;
    
        await axiosInstance.patch(url, 
            { title: newTitle },
            { params: { obj_doc_id: objDoc._id } }
        );
    
        return true;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // console.error('타이틀 수정 중 오류 발생:', error);
        }
        return false;
    }
};

// 키워드 수정
export const updateKeywords = async (objDoc: ObjDoc, newKeywords: string[]): Promise<boolean> => {
    try {
        if (!objDoc._id) {
            throw new Error('Invalid objDoc: missing _id');
        }
    
        const objType = getObjectType(objDoc);
        const url = `/api/v1/${objType}s/keyword`;
    
        await axiosInstance.patch(url, 
            { keywords: newKeywords },
            { params: { obj_doc_id: objDoc._id } }
        );
    
        return true;
    } catch (error) {
        if (axios.isAxiosError(error)) {
        }
        return false;
    }
};

// 서버에서 데이터를 받아올 때 사용할 수 있는 유틸리티 함수
export const processServerData = (data: any): ObjDoc => {
    if (!data || typeof data !== 'object') {
        throw new Error('Invalid server data');
    }

    const objType = getObjectType(data);
    
    // 타입에 따라 필요한 필드들이 있는지 확인
    switch (objType) {
        case 'file':
            if (!data.fileType || !data.fileKey) {
                throw new Error('Invalid file data');
            }
            break;
        case 'piece':
            if (!data.body) {
                throw new Error('Invalid piece data');
            }
            break;
        // article은 특별한 필드 검증이 필요 없음
    }

    return { ...data, type: objType };
};

// 타입 가드 함수들
export const isArticle = (item: ObjDoc): item is ObjDoc & { type: 'article' } => 
    getObjectType(item) === 'article';

export const isPiece = (item: ObjDoc): item is ObjDoc & { type: 'piece', body: string } => 
    getObjectType(item) === 'piece';

export const isFile = (item: ObjDoc): item is ObjDoc & { type: 'file', fileType: string, fileKey: string } => 
    getObjectType(item) === 'file';


// Docs 조회
export const getObjDocs = async (objType: ObjType, page: number = 1, size: number = 1): Promise<ObjDoc[]> => {
    try {
        const response = await axiosInstance.get(`/api/v1/objs/`, {
            params: { 
                obj_type: objType,
                page, 
                size 
            }
        });

        // 응답 데이터가 배열이 아닐 경우 객체 내에 배열이 있는지 확인
        const data = Array.isArray(response.data) ? response.data : response.data.items || [];
        
        return data.map((item: any) => ({
            ...item,
            type: getObjectType(item)
        }));
    } catch (error) {
        // console.error(`Error fetching ${objType} list:`, error);
        throw error;
    }
};

// Docs 개별 조회
export const getObjById = async (objDoc: ObjDoc): Promise<ObjDoc> => {
    if (!objDoc._id) {
        throw new Error('Invalid object: missing _id');
    }

    const objType = getObjectType(objDoc);
    
    try {
        const response = await axiosInstance.get<ObjDoc>(`/api/v1/${objType}s/${objDoc._id}`, {
            params: {
                obj_type: objType,
                obj_doc_id: objDoc._id
            }
        });
        return response.data;
    } catch (error) {
        // console.error(`${objType} 정보 가져오기 실패:`, error);
        throw error;
    }
};

// _class_id에서 'ObjDoc.' 부분을 제거하여 obj_type을 추출하는 함수
export const extractObjType = (_class_id: string): 'article' | 'piece' | 'file' => {
    const objType = _class_id.replace('ObjDoc.', '').toLowerCase(); 
    if (['article', 'piece', 'file'].includes(objType)) {
        return objType as 'article' | 'piece' | 'file';
    } else {
        throw new Error('Invalid class_id format'); 
    }
};

export const ObjDocIdBy = async (objDoc: ObjDoc) => {
    try {
        const objType = extractObjType(objDoc._class_id);
        
        // 서버로 obj_type과 _id를 전달
        const response = await axiosInstance.get(`/api/v1/${objType}s/${objDoc._id}`);
        
        return response.data; 
    } catch (error) {
        // console.error('Error fetching object document:', error);
        throw error; 
    }
};

export const SearchIdBy = async (autoCompleteItem: AutocompleteItem) => {
    try {
        // 서버로 objType과 id 전달하여 문서 가져오기
        const response = await axiosInstance.get(`/api/v1/objs/${autoCompleteItem.id}/`);
        return response.data; 
    } catch (error) {
        // console.error('Error fetching object document:', error);
        throw error; 
    }
};


export const PinIdBy = async ( objId: string) => {
    try {
        
        const response = await axiosInstance.get(`/api/v1/objs/${objId}/`);
        
        return response.data; // 서버에서 반환된 데이터를 리턴
    } catch (error) {
        // console.error('Error fetching object document:', error);
        throw error; 
    }
};