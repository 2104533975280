import React from 'react';
import { 
    ClockCircleOutlined,
    SmileOutlined,
    UserOutlined,
    HeartOutlined,
    BugOutlined,
    CoffeeOutlined,
    TrophyOutlined,
    CarOutlined,
    CloudOutlined,
    ToolOutlined,
    FontSizeOutlined,
    FlagOutlined,
    IdcardOutlined,
    TeamOutlined,
} from '@ant-design/icons';

export interface CategoryData {
    icon: React.ReactNode;
    emoji: string;
    emojis: string[];
    keywords: string[];
}

export interface Categories {
    [key: string]: CategoryData;
}

export interface SearchResult {
    category: string;
    emojis: string[];
}

export const categories: Categories = {
    "최근": {
        icon: React.createElement(ClockCircleOutlined),
        emoji: "🕒",
        emojis: [],
        keywords: []
    },
    "표정 & 감정": {
        icon: React.createElement(SmileOutlined),
        emoji: "😊",
        emojis: [
            "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", 
            "😉", "😌", "😍", "🥰", "😘", "😗", "😚", "😋", "😛", "😝", "😜", "🤪",
            "🤨", "🧐", "🤓", "😎", "🥸", "🤩", "🥳", "😏", "😒", "😞", "😔", "😟",
            "😕", "🙁", "☹️", "😣", "😖", "😫", "😩", "🥺", "😢", "😭", "😮‍💨", "😤",
            "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓",
            "🫣", "🤗", "🫡", "🤔", "🫢", "🤭", "🤫", "🤥", "😶", "😐", "😑", "😬"
        ],
        keywords: ["face", "smile", "laugh", "sad", "angry", "emotion", "표정", "감정", "웃음", "슬픔", "화남"]
    },
    "사람 & 신체": {
        icon: React.createElement(UserOutlined),
        emoji: "👤",
        emojis: [
            "👶", "👧", "🧒", "👦", "👩", "🧑", "👨", "👩‍🦱", "🧑‍🦱", "👨‍🦱", "👩‍🦰", 
            "🧑‍🦰", "👨‍🦰", "👱‍♀️", "👱", "👱‍♂️", "👩‍🦳", "🧑‍🦳", "👨‍🦳", "👩‍🦲", 
            "🧑‍🦲", "👨‍🦲", "🧔‍♀️", "🧔", "🧔‍♂️", "👵", "🧓", "👴", "👲", "👳‍♀️",
            "👋", "🤚", "🖐️", "✋", "🖖", "👌", "🤌", "🤏", "✌️", "🤞", "🫰", "🤟",
            "🤘", "🤙", "👈", "👉", "👆", "🖕", "👇", "☝️", "👍", "👎", "✊", "👊",
            "🤛", "🤜", "👏", "🙌", "👐", "🤲", "🤝", "🙏", "✍️", "💅", "🤳", "💪"
        ],
        keywords: ["person", "body", "hand", "eye", "people", "사람", "신체", "몸", "손", "눈"]
    },
    "하트 & 사랑": {
        icon: React.createElement(HeartOutlined),
        emoji: "❤️",
        emojis: [
            "❤️", "🧡", "💛", "💚", "💙", "💜", "🖤", "🤍", "🤎", "💔", "❤️‍🔥", "❤️‍🩹",
            "❣️", "💕", "💞", "💓", "💗", "💖", "💘", "💝", "💟", "♥️", "💌", "💋",
            "👥", "👤", "🫂", "💑", "👩‍❤️‍👨", "👨‍❤️‍👨", "👩‍❤️‍👩", "💏", "👩‍❤️‍💋‍👨",
            "👨‍❤️‍💋‍👨", "👩‍❤️‍💋‍👩"
        ],
        keywords: ["heart", "love", "couple", "하트", "사랑", "커플"]
    },
    "동물 & 자연": {
        icon: React.createElement(BugOutlined),
        emoji: "🐾",
        emojis: [
        "🐶", "🐱", "🐭", "🐹", "🐰", "🦊", "🐻", "🐼", "🐻‍❄️", "🐨", "🐯", "🦁",
        "🐮", "🐷", "🐽", "🐸", "🐵", "🐔", "🐧", "🐦", "🐤", "🦆", "🦅", "🦉",
        "🦇", "🐺", "🐗", "🐴", "🦄", "🐝", "🪱", "🐛", "🦋", "🐌", "🐞", "🐜",
        "🪰", "🪲", "🪳", "🦟", "🦗", "🕷️", "🕸️", "🦂", "🐢", "🐍", "🦎", "🦖",
        "🌵", "🎄", "🌲", "🌳", "🌴", "🌱", "🌿", "☘️", "🍀", "🎍", "🪴", "🎋",
        "🍃", "🍂", "🍁", "🌺", "🌸", "🌼", "🌻", "🌞", "🌝", "🌛", "🌜", "🌚"
        ],
        keywords: ["animal", "nature", "plant", "flower", "동물", "자연", "식물", "꽃"]
    },
    "음식 & 음료": {
        icon: React.createElement(CoffeeOutlined),
        emoji: "🍔",
        emojis: [
        "🍏", "🍎", "🍐", "🍊", "🍋", "🍌", "🍉", "🍇", "🍓", "🫐", "🍈", "🍒",
        "🍑", "🥭", "🍍", "🥥", "🥝", "🍅", "🍆", "🥑", "🥦", "🥬", "🥒", "🌶️",
        "🫑", "🌽", "🥕", "🫒", "🧄", "🧅", "🥔", "🍠", "🥐", "🥯", "🍞", "🥖",
        "🥨", "🧀", "🥚", "🍳", "🧈", "🥞", "🧇", "🥓", "🥩", "🍗", "🍖", "🦴",
        "🌭", "🍔", "🍟", "🍕", "🫓", "🥪", "🥙", "🧆", "🌮", "🌯", "🫔", "🥗",
        "☕", "🫖", "🍵", "🧃", "🥤", "🧋", "🍶", "🍺", "🍻", "🥂", "🍷", "🥃",
        "🍸", "🍹", "🧉", "🍾", "🧊", "🥄", "🍴", "🍽️", "🥢", "🥡"
        ],
        keywords: ["food", "drink", "fruit", "vegetable", "음식", "음료", "과일", "채소"]
    },
    "활동 & 스포츠": {
        icon: React.createElement(TrophyOutlined),
        emoji: "⚽",
        emojis: [
        "⚽", "🏀", "🏈", "⚾", "🥎", "🎾", "🏐", "🏉", "🥏", "🎱", "🪀", "🏓",
        "🏸", "🏒", "🏑", "🥍", "🏏", "🪃", "🥅", "⛳", "🪁", "🏹", "🎣", "🤿",
        "🎽", "🎿", "🛷", "🥌", "⛸️", "🛼", "🎮", "🎲", "🎭", "🎨", "🎼", "🎧",
        "🎤", "🎬", "🎯", "🎪", "🎟️", "🎫", "🎖️", "🏆", "🥇", "🥈", "🥉", "⚡",
        "💫", "⭐", "🌟", "✨", "⚡", "🎈", "🎉", "🎊", "🎋", "🎍", "🎎", "🎏",
        "🎐", "🎑", "🎀", "🎁", "🎗️", "🎟️", "🎫"
        ],
        keywords: ["activity", "sport", "game", "play", "활동", "스포츠", "게임", "운동"]
    },
    "교통 & 장소": {
        icon: React.createElement(CarOutlined),
        emoji: "🚗",
        emojis: [
        "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚐", "🛻", "🚚",
        "🚛", "🚜", "🛵", "🏍️", "🛺", "🚲", "🛴", "🚨", "🚔", "🚍", "🚘", "🚖",
        "🛩️", "✈️", "🚀", "🛸", "🚁", "🛶", "⛵", "🚤", "🛥️", "🛳️", "⛴️", "🚢",
        "⚓", "🚉", "🚊", "🚇", "🚝", "🚋", "🚃", "🚎", "🚅", "🚆", "🚂", "🚈",
        "🏔️", "⛰️", "🌋", "🗻", "🏕️", "🏖️", "🏜️", "🏝️", "🏞️", "🏟️", "🏛️", "🏗️",
        "🏘️", "🏚️", "🏠", "🏡", "🏢", "🏣", "🏤", "🏥", "🏦", "🏨", "🏩", "🏪",
        "🏫", "🏬", "🏭", "🏯", "🏰", "💒", "🗼", "🗽", "⛪", "🕌", "🕍", "⛩️",
        "🕋", "⛲", "⛺", "🌁", "🌃", "🏙️", "🌅", "🌄", "🌆", "🌇", "🌉", "♨️",
        "🎠", "🎡", "🎢", "💈", "🎪"
        ],
        keywords: ["transport", "place", "vehicle", "building", "교통", "장소", "차량", "건물"]
    },
    "날씨 & 하늘": {
        icon: React.createElement(CloudOutlined),
        emoji: "☀️",
        emojis: [
        "☀️", "🌤️", "⛅", "🌥️", "☁️", "🌦️", "🌧️", "⛈️", "🌩️", "🌨️", "❄️", "💨",
        "🌪️", "🌈", "☔", "⚡", "⭐", "🌟", "✨", "⚡", "🌙", "🌘", "🌗", "🌖",
        "🌕", "🌔", "🌓", "🌒", "🌑", "🌍", "🌎", "🌏", "🌐", "🪐", "💫"
        ],
        keywords: ["weather", "sky", "sun", "moon", "star", "날씨", "하늘", "태양", "달", "별"]
    },
    "물건 & 도구": {
        icon: React.createElement(ToolOutlined),
        emoji: "🔧",
        emojis: [
        "⌚", "📱", "📲", "💻", "⌨️", "🖥️", "🖨️", "🖱️", "🖲️", "🕹️", "🗜️", "💽",
        "💾", "💿", "📀", "📼", "📷", "📸", "📹", "🎥", "📽️", "🎞️", "📞", "☎️",
        "📟", "📠", "📺", "📻", "🎙️", "🎚️", "🎛️", "⏱️", "⏲️", "⏰", "🕰️", "⌛",
        "⏳", "📡", "🔋", "🔌", "💡", "🔦", "🕯️", "🪔", "🧯", "🛢️", "💸", "💵",
        "💴", "💶", "💷", "💰", "💳", "💎", "⚖️", "🪮", "🧰", "🪛", "🔧", "🔨",
        "⚒️", "🛠️", "⛏️", "🪚", "🔩", "⚙️", "🪤", "🧱", "⛓️", "🧲", "🔫", "💣",
        "🧨", "🪓", "🔪", "🗡️", "⚔️", "🛡️", "🚬", "⚰️", "⚱️", "🏺", "🔮", "📿",
        "🧿", "🪬", "💈", "⚗️", "🔭", "🔬", "🕳️", "🩹", "🩺", "💊", "💉", "🩸"
        ],
        keywords: ["object", "tool", "device", "물건", "도구", "기기"]
    },
    "기호 & 문자": {
        icon: React.createElement(FontSizeOutlined),
        emoji: "💬",
        emojis: [
            "💯", "✅", "❌", "❎", "➕", "➖", "➗", "✖️", "♾️", "💲", "💱", "〽️",
            "⚕️", "♻️", "⚜️", "🔱", "📛", "🔰", "⭕", "✴️", "❇️", "✳️", "❗", "❓",
            "❔", "❕", "♈", "♉", "♊", "♋", "♌", "♍", "♎", "♏", "♐", "♑", "♒",
            "♓", "⛎", "♀️", "♂️", "⚧️", "✝️", "☪️", "🕉️", "☸️", "✡️", "🔯", "🕎",
            "☯️", "☦️", "🛐", "⛪", "🕌", "🕍", "⚛️", "🧧", "✨", "🎈", "🎉", "🎊",
            "🔍", "🔎", "🔅", "🔆", "📧", "✉️", "📩", "📨", "📫", "📪", "📬", "📭",
            "📮", "🗃️", "🗄️", "🗑️", "🔒", "🔓", "🔏", "🔐", "🔑", "🗝️", "🏷️", "💟", 
            "☮️", "✝️", "☪️", "🕉️", "☸️", "✡️","🔯", "🕎", "☯️", "☦️", "🛐", "⛎", 
            "♈", "♉", "♊", "♋", "♌", "♍", "♎","♏", "♐", "♑", "♒", "♓"
        ],
        keywords: ["symbol", "sign", "mark", "기호", "문자", "표시"]
    },
    "국기 & 상징": {
        icon: React.createElement(FlagOutlined),
        emoji: "🏁",
        emojis: [
            "🏁", "🚩", "🎌", "🏴", "🏳️", "🏳️‍🌈", "🏳️‍⚧️", "🏴‍☠️",
            "🇦🇨", "🇦🇩", "🇦🇪", "🇦🇫", "🇦🇬", "🇦🇮", "🇦🇱", "🇦🇲", "🇦🇴",
            "🇦🇶", "🇦🇷", "🇦🇸", "🇦🇹", "🇦🇺", "🇦🇼", "🇦🇽", "🇦🇿", "🇧🇦",
            "🇧🇧", "🇧🇩", "🇧🇪", "🇧🇫", "🇧🇬", "🇧🇭", "🇧🇮", "🇧🇯", "🇧🇱",
            "🇧🇲", "🇧🇳", "🇧🇴", "🇧🇶", "🇧🇷", "🇧🇸", "🇧🇹", "🇧🇻", "🇧🇼",
            "🇧🇾", "🇧🇿", "🇨🇦", "🇨🇨", "🇨🇩", "🇨🇫", "🇨🇬", "🇨🇭", "🇨🇮",
            "🇨🇰", "🇨🇱", "🇨🇲", "🇨🇳", "🇨🇴", "🇨🇵", "🇨🇷", "🇨🇺", "🇨🇻",
            "🇨🇼", "🇨🇽", "🇨🇾", "🇨🇿", "🇩🇪", "🇩🇬", "🇩🇯", "🇩🇰", "🇩🇲",
            "🇩🇴", "🇩🇿", "🇪🇦", "🇪🇨", "🇪🇪", "🇪🇬", "🇪🇭", "🇪🇷", "🇪🇸",
            "🇪🇹", "🇪🇺", "🇫🇮", "🇫🇯", "🇫🇰", "🇫🇲", "🇫🇴", "🇫🇷", "🇬🇦",
            "🇬🇧", "🇬🇩", "🇬🇪", "🇬🇫", "🇬🇬", "🇬🇭", "🇬🇮", "🇬🇱", "🇬🇲",
            "🇬🇳", "🇬🇵", "🇬🇶", "🇬🇷", "🇬🇸", "🇬🇹", "🇬🇺", "🇬🇼", "🇬🇾",
            "🇭🇰", "🇭🇲", "🇭🇳", "🇭🇷", "🇭🇹", "🇭🇺", "🇮🇨", "🇮🇩", "🇮🇪",
            "🇮🇱", "🇮🇲", "🇮🇳", "🇮🇴", "🇮🇶", "🇮🇷", "🇮🇸", "🇮🇹", "🇯🇪",
            "🇯🇲", "🇯🇴", "🇯🇵", "🇰🇪", "🇰🇬", "🇰🇭", "🇰🇮", "🇰🇲", "🇰🇳",
            "🇰🇵", "🇰🇷", "🇰🇼", "🇰🇾", "🇰🇿", "🇱🇦", "🇱🇧", "🇱🇨", "🇱🇮",
            "🇱🇰", "🇱🇷", "🇱🇸", "🇱🇹", "🇱🇺", "🇱🇻", "🇱🇾", "🇲🇦", "🇲🇨",
            "🇲🇩", "🇲🇪", "🇲🇫", "🇲🇬", "🇲🇭", "🇲🇰", "🇲🇱", "🇲🇲", "🇲🇳",
            "🇲🇴", "🇲🇵", "🇲🇶", "🇲🇷", "🇲🇸", "🇲🇹", "🇲🇺", "🇲🇻", "🇲🇼",
            "🇲🇽", "🇲🇾", "🇲🇿", "🇳🇦", "🇳🇨", "🇳🇪", "🇳🇫", "🇳🇬", "🇳🇮",
            "🇳🇱", "🇳🇴", "🇳🇵", "🇳🇷", "🇳🇺", "🇳🇿", "🇴🇲", "🇵🇦", "🇵🇪",
            "🇵🇫", "🇵🇬", "🇵🇭", "🇵🇰", "🇵🇱", "🇵🇲", "🇵🇳", "🇵🇷", "🇵🇸",
            "🇵🇹", "🇵🇼", "🇵🇾", "🇶🇦", "🇷🇪", "🇷🇴", "🇷🇸", "🇷🇺", "🇷🇼",
            "🇸🇦", "🇸🇧", "🇸🇨", "🇸🇩", "🇸🇪", "🇸🇬", "🇸🇭", "🇸🇮", "🇸🇯",
            "🇸🇰", "🇸🇱", "🇸🇲", "🇸🇳", "🇸🇴", "🇸🇷", "🇸🇸", "🇸🇹", "🇸🇻",
            "🇸🇽", "🇸🇾", "🇸🇿", "🇹🇦", "🇹🇨", "🇹🇩", "🇹🇫", "🇹🇬", "🇹🇭",
            "🇹🇯", "🇹🇰", "🇹🇱", "🇹🇲", "🇹🇳", "🇹🇴", "🇹🇷", "🇹🇹", "🇹🇻",
            "🇹🇼", "🇹🇿", "🇺🇦", "🇺🇬", "🇺🇲", "🇺🇳", "🇺🇸", "🇺🇾", "🇺🇿",
            "🇻🇦", "🇻🇨", "🇻🇪", "🇻🇬", "🇻🇮", "🇻🇳", "🇻🇺", "🇼🇫", "🇼🇸",
            "🇽🇰", "🇾🇪", "🇾🇹", "🇿🇦", "🇿🇲", "🇿🇼"
        ],
        keywords: ["flag", "symbol", "nation", "국기", "상징", "나라"]
    },
    "직업 & 역할": {
        icon: React.createElement(IdcardOutlined),
        emoji: "👨‍💼",
        emojis: [
        "👨‍⚕️", "👩‍⚕️", "👨‍🎓", "👩‍🎓", "👨‍🏫", "👩‍🏫", "👨‍⚖️", "👩‍⚖️", "👨‍🌾", "👩‍🌾",
        "👨‍🍳", "👩‍🍳", "👨‍🔧", "👩‍🔧", "👨‍🏭", "👩‍🏭", "👨‍💼", "👩‍💼", "👨‍🔬", "👩‍🔬",
        "👨‍💻", "👩‍💻", "👨‍🎤", "👩‍🎤", "👨‍🎨", "👩‍🎨", "👨‍✈️", "👩‍✈️", "👨‍🚀", "👩‍🚀",
        "👨‍🚒", "👩‍🚒", "👮‍♂️", "👮‍♀️", "🕵️‍♂️", "🕵️‍♀️", "💂‍♂️", "💂‍♀️", "👷‍♂️", "👷‍♀️"
        ],
        keywords: ["job", "profession", "occupation", "직업", "직종", "역할"]
    },
    "가족 & 커플": {
        icon: React.createElement(TeamOutlined),
        emoji: "👨‍👩‍👦",
        emojis: [
        "👨‍👩‍👦", "👨‍👩‍👧", "👨‍👩‍👧‍👦", "👨‍👩‍👦‍👦", "👨‍👩‍👧‍👧", "👩‍👩‍👦", "👩‍👩‍👧",
        "👩‍👩‍👧‍👦", "👩‍👩‍👦‍👦", "👩‍👩‍👧‍👧", "👨‍👨‍👦", "👨‍👨‍👧", "👨‍👨‍👧‍👦", "👨‍👨‍👦‍👦",
        "👨‍👨‍👧‍👧", "👩‍👦", "👩‍👧", "👩‍👧‍👦", "👩‍👦‍👦", "👩‍👧‍👧", "👨‍👦", "👨‍👧",
        "👨‍👧‍👦", "👨‍👦‍👦", "👨‍👧‍👧", "💑", "👩‍❤️‍👨", "👨‍❤️‍👨", "👩‍❤️‍👩", "💏",
        "👩‍❤️‍💋‍👨", "👨‍❤️‍💋‍👨", "👩‍❤️‍💋‍👩"
        ],
        keywords: ["family", "couple", "relationship", "가족", "커플", "관계"]
    }
};

// Utility functions
export const getRecentEmojis = () => {
    try {
        const saved = localStorage.getItem('recentEmojis');
        return saved ? JSON.parse(saved) : [];
    } catch {
        return [];
    }
};

export const saveRecentEmoji = (emoji: string) => {
    try {
        let recent = getRecentEmojis();
        recent = [emoji, ...recent.filter((e: string) => e !== emoji)].slice(0, 20);
        localStorage.setItem('recentEmojis', JSON.stringify(recent));
        categories["최근"].emojis = recent;
        return recent;
    } catch {
        return [];
    }
};

export const searchEmojis = (query: string): SearchResult[] => {
    if (!query.trim()) return [];

    const lowerQuery = query.toLowerCase();
    const results: SearchResult[] = [];

    Object.entries(categories).forEach(([category, data]) => {
        if (category === "최근") return;

        const matches = new Set<string>();

        // 카테고리 이름 매칭
        if (category.toLowerCase().includes(lowerQuery)) {
        data.emojis.forEach(emoji => matches.add(emoji));
        }

        // 키워드 매칭
        if (data.keywords.some(keyword => keyword.toLowerCase().includes(lowerQuery))) {
        data.emojis.forEach(emoji => matches.add(emoji));
        }

        // 이모지 직접 매칭
        data.emojis.forEach(emoji => {
        if (emoji.toLowerCase().includes(lowerQuery)) {
            matches.add(emoji);
        }
        });

        if (matches.size > 0) {
        results.push({
            category,
            emojis: Array.from(matches)
        });
        }
    });

    return results;
};

export default {
    categories,
    getRecentEmojis,
    saveRecentEmoji,
    searchEmojis
}; 