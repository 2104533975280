// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import { Piece } from '../types/Picec'; 
// import { Skeleton, Tag } from 'antd';

// const PieceBodyPage: React.FC = () => {
//   const location = useLocation();
//   const piece = location.state?.piece as Piece;  // 전달된 piece 데이터

//   return (
//     <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
//       {piece ? (
//         <>
//           <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>{piece.title}</h1>
//           <p style={{ textAlign: 'center', color: '#888' }}>
//             {new Date(piece.createdAt).toLocaleDateString()}
//           </p>
//           {piece.linkUrl && (
//             <p style={{ textAlign: 'center' }}>
//               <a href={piece.linkUrl} target="_blank" rel="noopener noreferrer">
//                 관련 링크 보기
//               </a>
//             </p>
//           )}
//           <div style={{ whiteSpace: 'pre-wrap', marginBottom: '20px' }}>
//             {piece.body}
//           </div>
//           <div style={{ marginTop: '20px' }}>
//             {piece.keywords?.map((keyword, index) => (
//               <Tag key={index} color="blue">
//                 #{keyword}
//               </Tag>
//             ))}
//           </div>
//         </>
//       ) : (
//         <Skeleton active />
//       )}
//     </div>
//   );
// };

// export default PieceBodyPage;


import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Piece } from '../types/Picec';
import { Skeleton, Tag, Input, Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PieceBodyPage: React.FC = () => {
  const location = useLocation();
  const piece = location.state?.piece as Piece;
  
  const [editedTitle, setEditedTitle] = useState(piece?.title || '');
  const [editedContent, setEditedContent] = useState(piece?.body || '');
  const [isEditing, setIsEditing] = useState(false);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']
    ],
  };

  // HTML 콘텐츠를 보여주기 위한 스타일
  const contentStyle = {
    width: '100%',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    '& p': {
      marginBottom: '0.8em',
      lineHeight: '1.6'
    }
  };

  const handleSave = () => {
    if (piece) {
      const updatedPiece = {
        ...piece,
        title: editedTitle,
        body: editedContent // 에디터에서 나온 HTML 콘텐츠
      };
      console.log('Saving piece:', updatedPiece);
      // TODO: API 호출
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedTitle(piece?.title || '');
    setEditedContent(piece?.body || '');
    setIsEditing(false);
  };

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '20px' }}>
      {piece ? (
        <>
          {/* 제목 영역 */}
          {isEditing ? (
            <Input 
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              placeholder="제목 수정"
              style={{ marginBottom: '10px' }}
              size="large"
            />
          ) : (
            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>{piece.title}</h1>
          )}

          {/* 날짜와 링크 */}
          <p style={{ textAlign: 'center', color: '#888' }}>
            {new Date(piece.createdAt).toLocaleDateString()}
          </p>
          {piece.linkUrl && (
            <p style={{ textAlign: 'center' }}>
              <a href={piece.linkUrl} target="_blank" rel="noopener noreferrer">
                관련 링크 보기
              </a>
            </p>
          )}

          {/* 버튼 영역 */}
          <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            {isEditing ? (
              <>
                <Button 
                  type="primary" 
                  onClick={handleSave}
                  style={{ marginRight: '8px' }}
                >
                  저장
                </Button>
                <Button onClick={handleCancel}>
                  취소
                </Button>
              </>
            ) : (
              <Button type="primary" onClick={() => setIsEditing(true)}>
                수정
              </Button>
            )}
          </div>

          {/* 콘텐츠 영역 */}
          {isEditing ? (
            <div style={{ height: '600px', marginBottom: '50px' }}>
              <ReactQuill
                theme="snow"
                value={editedContent}
                onChange={setEditedContent}
                modules={modules}
                style={{ height: '500px' }}
              />
            </div>
          ) : (
            <div 
              style={contentStyle}
              dangerouslySetInnerHTML={{ __html: piece.body }} 
            />
          )}

          {/* 키워드 태그 */}
          <div style={{ marginTop: '20px' }}>
            {piece.keywords?.map((keyword, index) => (
              <Tag 
                key={index} 
                color="blue" 
                style={{ borderRadius: '20px', padding: '2px 10px' }}
              >
                #{keyword}
              </Tag>
            ))}
          </div>
        </>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
};

export default PieceBodyPage;


