import axiosInstance from '../utils/axiosInstance';
import { UserInfo } from '../types/user';


export const getUserInfo = async (): Promise<UserInfo> => {
    try {
        const response = await axiosInstance.get<UserInfo>('/api/v1/user/info');
        return response.data;
    } catch (error) {
        // console.error('Error fetching user info:', error);
        throw error;
    }
};
