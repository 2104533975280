import React from 'react';
import { Form, Input, Button, Typography, App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { signup } from '../api/auth';

const { Title, Text } = Typography;

const SignUpPage: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const validatePassword = (rule: any, value: string) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (!value) {
            return Promise.reject('비밀번호를 입력해 주세요.');
        }
        if (!regex.test(value)) {
            return Promise.reject('숫자, 영문, 특수문자를 포함해서 8자 이상으로 입력해 주세요.');
        }
        return Promise.resolve();
    };

    const onFinish = async (values: { email: string; password: string }) => {
        try {
            await signup(values.email, values.password);
            message.success('회원가입이 완료되었습니다. 로그인 페이지로 이동합니다.');
            navigate('/signin');
        } catch (error) {
            if (error instanceof Error) {
                message.error(error.message);
            } else {
                message.error('회원가입에 실패했습니다. 다시 시도해주세요.');
            }
        }
    };

    return (
        <App>

            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', 
                background: 'linear-gradient(to right, #f0f2f5, #dfe9f3)' 
            }}>
                <div style={{ 
                    width: '100%', 
                    maxWidth: '400px', 
                    padding: '40px', 
                    background: 'white', 
                    borderRadius: '12px', 
                    boxShadow: '0 4px 16px rgba(0,0,0,0.1)', 
                    textAlign: 'center'
                }}>
                    <img 
                        src={`${process.env.PUBLIC_URL}/images/logo-long.png`} 
                        alt="CEEP 로고" 
                        style={{ 
                            width: '200px', 
                            marginBottom: '20px'
                        }} 
                    />
                    <Title level={3} style={{ marginBottom: '30px' }}>회원가입</Title>
                    
                    <Form
                        form={form}
                        name="signup"
                        onFinish={onFinish}
                        layout="vertical"
                        requiredMark={false}
                    >
                        <Form.Item
                            name="email"
                            label={<>이메일 <Text type="danger">*</Text></>}
                            rules={[
                                { required: true, message: '이메일을 입력해 주세요.' },
                                { type: 'email', message: '올바른 이메일 형식이 아닙니다.' }
                            ]}
                        >
                            <Input placeholder="이메일을 입력해 주세요." size='large' />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label={<>비밀번호 <Text type="danger">*</Text></>}
                            rules={[
                                { validator: validatePassword }
                            ]}
                        >
                            <Input.Password placeholder="비밀번호를 입력해 주세요." size='large' />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            label={<>비밀번호 확인 <Text type="danger">*</Text></>}
                            dependencies={['password']}
                            rules={[
                                { required: true, message: '비밀번호를 다시 입력해 주세요.' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="비밀번호를 다시 입력해 주세요." size='large'/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%', fontWeight: 'bold' }} size='large'>
                                가입 완료
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </App>
    );
};

export default SignUpPage;