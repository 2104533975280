import React, { useState, useEffect, useRef } from 'react';
import { Typography, Card, Space, Divider, List, Row, Col, Menu, Affix, Button, Drawer, theme } from 'antd';
import { 
  InfoCircleOutlined, 
  BookOutlined, 
  UnorderedListOutlined,
  SafetyCertificateOutlined,
  DeleteOutlined,
  TeamOutlined,
  SettingOutlined,
  SecurityScanOutlined,
  RightOutlined,
  UpOutlined,
  MenuOutlined
} from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;
const { useToken } = theme;

interface ListItem {
  key: string;
  category?: string;
  title?: string;
  items: string[];
}

interface CollectionData {
  key: string;
  category: string;
  items: string[];
}

const sections = [
  { key: '1', title: '총칙', icon: <SafetyCertificateOutlined /> },
  { key: '2', title: '수집하는 개인정보의 항목 및 수집방법', icon: <UnorderedListOutlined /> },
  { key: '3', title: '개인정보의 수집 및 이용 목적', icon: <SettingOutlined /> },
  { key: '4', title: '개인정보의 보유 및 이용 기간', icon: <SecurityScanOutlined /> },
  { key: '5', title: '개인정보의 파기 절차 및 방법', icon: <DeleteOutlined /> },
  { key: '6', title: '개인정보의 제3자 제공', icon: <TeamOutlined /> },
  { key: '7', title: '이용자 및 법정대리인의 권리와 그 행사 방법', icon: <TeamOutlined /> },
  { key: '8', title: '개인정보 자동 수집 장치의 설치/운영 및 거부', icon: <SettingOutlined /> },
  { key: '9', title: '개인정보의 안전성 확보 조치', icon: <SafetyCertificateOutlined /> },
  { key: '10', title: '개인정보 보호책임자 및 담당부서', icon: <TeamOutlined /> },
  { key: '11', title: '개인정보 처리방침 변경', icon: <InfoCircleOutlined /> },
  { key: '12', title: '권익 침해 구제 방법', icon: <SafetyCertificateOutlined /> }
];

const TitleWithBar: React.FC<{ level?: 1 | 2 | 3 | 4 | 5; children: React.ReactNode }> = ({ level = 3, children }) => {
  const { token } = useToken();
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      <div
        style={{
          width: 4,
          height: 24,
          backgroundColor: token.colorPrimary,
          borderRadius: 2,
          flexShrink: 0
        }}
      />
      <Title level={level}>{children}</Title>
    </div>
  );
};
const PrivacyPolicy: React.FC = () => {
  const [currentSection, setCurrentSection] = useState('1');
  const [showTableOfContentsButton, setShowTableOfContentsButton] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false); // 화면 너비 상태
  const [currentDate, setCurrentDate] = useState<string>(''); // 현재 날짜 상태
  const affixRef = useRef<HTMLDivElement | null>(null);
  const { token } = useToken();

  const smallScreenThreshold = 768; // 너비 임계값

   // 현재 날짜 가져오기
  useEffect(() => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    setCurrentDate(formattedDate);
  }, []);

  // 화면 너비 감지
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= smallScreenThreshold);
    };

    // 초기 상태 설정
    handleResize();

    // 이벤트 리스너 추가
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Intersection Observer 설정
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowTableOfContentsButton(!entry.isIntersecting && isSmallScreen); // 소화면일 때만 표시
      },
      { rootMargin: '0px 0px 0px 0px' }
    );

    if (affixRef.current) {
      observer.observe(affixRef.current);
    }

    return () => {
      if (affixRef.current) observer.unobserve(affixRef.current);
    };
  }, [isSmallScreen]); // isSmallScreen 변경 시 재실행

  // 스크롤 버튼 관련 이벤트
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToSection = (key: string) => {
    setCurrentSection(key);
    const element = document.getElementById(`section${key}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setDrawerVisible(false);
  };

  const cardStyle = {
    borderRadius: 12,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  };

  const TableOfContents = () => (
    <Menu
      mode="inline"
      selectedKeys={[currentSection]}
      onClick={({ key }) => scrollToSection(key)}
      style={{ border: 'none' }}
      items={sections.map(section => ({
        key: section.key,
        icon: section.icon,
        label: `${section.key}. ${section.title}`,
      }))}
    />
  );

  // 데이터 정의
  const collectionData: CollectionData[] = [
    {
      key: '1',
      category: '1. 필수 항목',
      items: ['이름', '이메일 주소', '비밀번호']
    },
    {
      key: '2',
      category: '2. 선택 항목',
      items: ['프로필 사진']
    },
    {
      key: '3',
      category: '3, 4. 개인정보 수집방법',
      items: ['회원가입 시 직접 입력']
    }
  ];

  const purposeData: CollectionData[] = [
    {
      key: '1',
      category: '1. 서비스 제공',
      items: ['Green 서비스의 개인별 관리']
    },
    {
      key: '2',
      category: '2. 서비스 개선',
      items: ['개인 맞춤형 서 모델 생성 향상']
    },
    {
      key: '3',
      category: '3. 고객 지원',
      items: ['고객 문의 사항 처리 및 공지사항 전달']
    }
  ];

  const securityMeasures: ListItem[] = [
    {
      key: '1',
      title: '관리적 조치',
      items: [
        '내부관리계획 수립 및 시행',
        '정기적 직원 교육'
      ]
    },
    {
      key: '2',
      title: '기술적 조치',
      items: [
        '개인정보처리시스템 접근 권한 관리',
        '암호화 및 보안 프로그램 설치'
      ]
    },
    {
      key: '3',
      title: '물리적 조치',
      items: [
        '전산실 및 자료보관실의 접근 통제'
      ]
    }
  ];
  const renderSection = (sectionKey: string) => {
    switch (sectionKey) {
      case '1':
        return (
          <>
            <Paragraph>
            Amuse8 주식회사(이하 '회사')는 'Ceep' 서비스 이용자의 개인정보 보호를 매우 중요하게 여기며, 
            「개인정보 보호법」 등 관련 법령을 준수하고 있습니다. 
            회사는 개인정보 처리방침을 통해 이용자의 개인정보가 어떤 용도와 방식으로 이용되고 있으며, 
            개인정보 보호를 위해 어떤 조치가 취해지고 있는지 알려드립니다.
            </Paragraph>
          </>
        );

      case '2':
        return (
          <>
            <Paragraph>
              회사는 서비스 제공 및 개선을 위해 다음과 같은 개인정보를 수집합니다.
            </Paragraph>
            <Card style={{ marginTop: 16 }}>
              {collectionData.map((category, index) => (
                <div key={category.key} style={{ marginBottom: index === collectionData.length - 1 ? 0 : 16 }}>
                  <Text strong>{category.category}</Text>
                  <ul style={{ margin: '8px 0 0 20px', paddingLeft: 0 }}>
                    {category.items.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </Card>
          </>
        );

      case '3':
        return (
          <Card style={{ marginTop: 16 }}>
            {purposeData.map((category, index) => (
              <div key={category.key} style={{ marginBottom: index === purposeData.length - 1 ? 0 : 16 }}>
                <Text strong>{category.category}</Text>
                <ul style={{ margin: '8px 0 0 20px', paddingLeft: 0 }}>
                  {category.items.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </Card>
        );

        case '4':
          return (
            <>
              <Paragraph>
                회사는 원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 
                단, 법령에 따라 보존이 필요한 경우 다음과 같이 보존합니다:
              </Paragraph>
              <List>
                <List.Item>
                  <Space>
                    <Text strong>회원 탈퇴 시까지: </Text>
                    서비스 이용에 필요한 최소한의 개인정보
                  </Space>
                </List.Item>
                <List.Item>
                  <Space>
                    <Text strong>관계 법령에 따른 정보 보유: </Text>
                    해당 법령에서 정한 기간 동안
                  </Space>
                </List.Item>
              </List>
            </>
          );
  
        case '5':
          return (
            <>
              <Paragraph>
                회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때 지체 없이 파기합니다.
              </Paragraph>
              <Title level={4}>파기 방법</Title>
              <List>
                <List.Item>
                  <Space>
                    <Text strong>전자적 파일 형태: </Text>
                    복구 및 재생되지 않도록 안전하게 삭제
                  </Space>
                </List.Item>
                <List.Item>
                  <Space>
                    <Text strong>기록물, 출력물 등: </Text>
                    분쇄하거나 소각
                  </Space>
                </List.Item>
              </List>
            </>
          );
  
        case '6':
          return (
            <>
              <Paragraph>
                회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다:
              </Paragraph>
              <List>
                <List.Item>1. 이용자가 사전에 동의한 경우</List.Item>
                <List.Item>2. 법령의 규정에 의거하거나 수사 목적으로 법령에서 정한 절차와 방법에 따라 수사기관이 요구하는 경우</List.Item>
              </List>
            </>
          );
  
        case '7':
          return (
            <>
              <Paragraph>
                이용자 및 법정대리인은 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 가입 해지를 요청할 수 있습니다.
              </Paragraph>
              <List>
                <List.Item>
                  개인정보 조회 및 수정은 <Text strong>'개인정보변경'</Text> 메뉴에서, 
                  가입 해지는 <Text strong>'회원탈퇴'</Text> 메뉴를 통해 가능합니다.
                </List.Item>
                <List.Item>회원 탈퇴 시 본인 확인 절차를 거쳐야 합니다.</List.Item>
              </List>
            </>
          );
  
        case '8':
          return (
            <List>
              <List.Item>
                <Space direction="vertical">
                  <Text strong>쿠키 사용</Text>
                  이용자 맞춤형 서비스를 제공하기 위해 쿠키를 사용합니다. 쿠키는 웹사이트가 이용자의 브라우저로 전송하는 소량의 데이터로, 
                  이용자의 디바이스에 저장됩니다.
                </Space>
              </List.Item>
              <List.Item>
                <Space direction="vertical">
                  <Text strong>쿠키 관리</Text>
                  이용자는 웹 브라우저 설정을 통해 쿠키 저장을 허용하거나 거부할 수 있습니다.
                </Space>
              </List.Item>
            </List>
          );
  
        case '9':
          return (
            <>
              <Paragraph>
                회사는 개인정보를 안전하게 보호하기 위해 다음과 같은 조치를 취하고 있습니다:
              </Paragraph>
              {securityMeasures.map((measure, index) => (
                <div key={measure.key} style={{ marginBottom: index === securityMeasures.length - 1 ? 0 : 16 }}>
                  <Text strong>{measure.title}</Text>
                  <ul style={{ margin: '8px 0 0 20px', paddingLeft: 0 }}>
                    {measure.items.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          );
          case '10':
            return (
              <>
                <Paragraph>
                  개인정보 처리와 관련된 불만처리 및 피해 구제를 위해 아래의 개인정보 보호책임자를 지정하고 있습니다.
                </Paragraph>
                <Card style={{ marginTop: 16 }}>
                  <Space direction="vertical">
                    <Text>
                      <Text strong>책임자 이름: </Text>
                      신동민
                    </Text>
                    <Text>
                      <Text strong>직책: </Text>
                        CTO
                    </Text>
                    <Text>
                      <Text strong>이메일: </Text>
                      dongmin@amuse8.kr
                    </Text>
                  </Space>
                </Card>
              </>
            );

          case '11':
            return (
              <Paragraph>
                이 개인정보 처리방침은 법령 및 회사 내부 정책에 따라 변경될 수 있습니다. 변경 사항은 회사 홈페이지를 통해 공지합니다.
              </Paragraph>
            );

          case '12':
            return (
              <>
                <Paragraph>
                  개인정보 침해로 인한 분쟁 해결을 위해 다음 기관에 상담을 신청할 수 있습니다:
                </Paragraph>
                <List>
                  <List.Item>
                    <Text>개인정보분쟁조정위원회</Text>
                  </List.Item>
                  <List.Item>
                    <Text>한국인터넷진흥원 개인정보침해신고센터</Text>
                  </List.Item>
                </List>
              </>
            );
  

      default:
        return null;
    }
  };
  return (
    <Row gutter={24} style={{ minHeight: '100vh', background: '#f5f5f5', padding: '24px' }}>
      {/* 데스크톱 목차 */}
      <Col xs={0} sm={0} md={6} lg={6} xl={5}>
        <div ref={affixRef}>
          <Affix offsetTop={24}>
            <Card style={cardStyle}>
              <Title level={4} style={{ marginBottom: 24 }}>
                <BookOutlined /> 목차
              </Title>
              <TableOfContents />
            </Card>
          </Affix>
        </div>
      </Col>

      {/* 메인 컨텐츠 */}
      <Col xs={24} sm={24} md={18} lg={18} xl={19}>
        <Card style={cardStyle}>
          <div style={{ textAlign: 'right', marginBottom: 24 }}>
            <Text type="secondary" style={{ fontSize: '14px' }}>
              <InfoCircleOutlined /> 최종 수정일: 2024년 11월 22일
            </Text>
          </div>

          <Title style={{ textAlign: 'center', marginBottom: 48 }}>개인정보 처리방침</Title>

          {sections.map(section => (
            <div
              key={section.key}
              id={`section${section.key}`}
              style={{
                marginBottom: section.key !== '9' ? 48 : 0,
                scrollMarginTop: '20px',
              }}
            >
              <TitleWithBar level={3}>
                {section.key}. {section.title}
              </TitleWithBar>
              <Divider />
              {renderSection(section.key)}
            </div>
          ))}
          <div style={{ textAlign: 'center', marginTop: 80 }}>
            <Paragraph style={{fontSize: 20}}>
              이 개인정보 처리방침은 <Text strong style={{fontSize: 20}}>{currentDate}</Text>부터 적용됩니다.
            </Paragraph>
          </div>
        </Card>
      </Col>

      {/* 플로팅 버튼들 */}
      {showTableOfContentsButton && (
        <Button
          type="default"
          shape="circle"
          icon={<MenuOutlined />}
          onClick={() => setDrawerVisible(true)}
          style={{
            position: 'fixed',
            right: '24px',
            bottom: '30px',
            width: '40px',
            height: '40px',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.8,
            transition: 'opacity 0.3s',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.opacity = '1';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.opacity = '0.8';
          }}
        />
      )}

      {/* 스크롤 탑 버튼 */}
      {showScrollButton && (
        <Button
          type="primary"
          shape="circle"
          icon={<UpOutlined />}
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            right: '24px',
            bottom: '80px',
            width: '40px',
            height: '40px',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.8,
            transition: 'opacity 0.3s',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.opacity = '1';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.opacity = '0.8';
          }}
        />
      )}

      {/* 목차 드로워 */}
      <Drawer
        title={
          <Space>
            <BookOutlined />
            목차
          </Space>
        }
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={300}
      >
        <TableOfContents />
      </Drawer>
    </Row>
  );
};

export default PrivacyPolicy;