import axiosInstance from '../utils/axiosInstance';
import { File as ServerFile } from '../types/File'; 
import { AxiosError } from 'axios';


// 파일 업로드 함수
export const uploadFile = async (file: globalThis.File, title?: string): Promise<ServerFile> => {
    const formData = new FormData();
    formData.append('upload_file', file);  
    if (title) formData.append('title', title);

    try {
        const response = await axiosInstance.post<ServerFile>('/api/v1/files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        return response.data; 
    } catch (error) {
        // console.error('파일 업로드 실패:', error);
        throw error;
    }
};


// 파일 다운로드 URL 생성
export const generatePresignedUrl = async (file_id: string) => {
    try {
        const response = await axiosInstance.post(`/api/v1/files/${file_id}/`);
        console.log('Api Presigned URL Response:', response.data); // 응답 데이터 확인
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response && error.response.status === 400) {
                console.error('Presigned URL generation failed: Signature expired');
            } else {
                console.error('Failed to generate presigned URL:', error.message);
            }
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


// 파일 확장자 목록 조회
export const getFileTypes = async () => {
    try {
        const response = await axiosInstance.get('/api/v1/files/file-types');
        return response.data.types;
    } catch (error) {
        // console.error('Failed to fetch file types:', error);
        throw error;
    }
};

// 파일 확장자별 조회
export const getFilesByType = async (file_type: string, page: number = 1, size: number = 15) => {
    try {
        const response = await axiosInstance.get(`/api/v1/files/file-types/${file_type}`, {
            params: { page, size },
        });
        return response.data;
    } catch (error) {
        // console.error(`Failed to fetch files for type ${file_type}:`, error);
        throw error;
    }
};