import React, { useState, useEffect, CSSProperties } from 'react';
import { Layout, Button } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import Sidebar from './Sidebar';
import Header from './Header';
import DailyUploadTimeline from './DailyUploadTimeline';

const { Content } = Layout;

interface AppLayoutProps {
  children: React.ReactNode;
  onUpload?: (newUpload: any) => void;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children, onUpload }) => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = sessionStorage.getItem('_sC');
    return savedState ? JSON.parse(savedState) : false;
  });
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    sessionStorage.setItem('_sC', JSON.stringify(collapsed));
  }, [collapsed]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 300); // 300px 이상 스크롤시 버튼 표시
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleCollapse = () => {
    setCollapsed((prevState: boolean) => !prevState);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // 사이드바 너비 설정
  const sidebarWidth = collapsed ? 80 : 200;

  const sidebarStyle: CSSProperties = {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1000,
  };

  const headerStyle: CSSProperties = {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    width: '100%',
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar
        collapsed={collapsed}
        toggleCollapse={toggleCollapse}
        onUpload={onUpload}
        customStyle={sidebarStyle}
      />
      <Layout style={{ 
        marginLeft: sidebarWidth,
        transition: 'all 0.2s'
      }}>
        <Header customStyle={headerStyle} />
        <Content
          style={{
            margin: 0,
            padding: 16,
            minHeight: 'calc(100vh - 64px)',
            background: '#fff',
          }}
        >
          {children}
        </Content>
        {showScrollButton && (
          <Button
            type="primary"
            shape="circle"
            icon={<UpOutlined />}
            onClick={scrollToTop}
            style={{
              position: 'fixed',
              right: '24px',
              bottom: '80px',
              width: '40px',
              height: '40px',
              zIndex: 1000,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0.8,
              transition: 'opacity 0.3s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.opacity = '1';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.opacity = '0.8';
            }}
          />
        )}
        <DailyUploadTimeline />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
