import React from 'react';
import { Modal } from 'antd';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const TokenExpirationModal: React.FC = () => {
    const { showLoginModal, handleTokenRefresh, setShowLoginModal } = useAuth();
    const navigate = useNavigate();

    const handleOk = () => {
        handleTokenRefresh();
    };

    const handleCancel = () => {
        setShowLoginModal(false);
        navigate('/signin')
    };

    return (
        <Modal
        title="토큰 만료"
        open={showLoginModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="재로그인"
        cancelText="취소"
        >
        <p>토큰이 만료되었습니다. 다시 로그인하시겠습니까?</p>
        </Modal>
    );
};

export default TokenExpirationModal;