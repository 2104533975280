import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, App } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { signin } from '../api/auth'; 
import { useAuth } from '../contexts/AuthContext';

const { Title, Text } = Typography;

const SignInPage: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { message } = App.useApp();
    const { login, isAuthenticated } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const onFinish = async (values: { username: string; password: string }) => {
        setIsLoading(true);
        try {
            // console.log('Signin attempt with:', values);  // 디버깅을 위한 로그
            const response = await signin(values.username, values.password);
            login(response.access_token, response.refresh_token, response.token_type);
            navigate('/');
        } catch (error) {
            if (error instanceof Error) {
                message.error(error.message);
            } else {
                message.error('로그인에 실패했습니다. 다시 시도해주세요.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <App>
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', 
                background: 'linear-gradient(to right, #f0f2f5, #dfe9f3)' 
            }}>
                <div style={{ 
                    width: '100%', 
                    maxWidth: '400px', 
                    padding: '40px', 
                    background: 'white', 
                    borderRadius: '12px', 
                    boxShadow: '0 4px 16px rgba(0,0,0,0.1)', 
                    textAlign: 'center'
                }}>
                    <img 
                        src={`${process.env.PUBLIC_URL}/images/logo-long.png`} 
                        alt="CEEP 로고" 
                        style={{ 
                            width: '200px', 
                            marginLeft: '20px'
                        }} 
                    />

                    <p style={{ 
                        marginBottom: '30px', 
                        fontSize: '18px',  
                        color: '#1F1F1F'
                    }}>
                        "세상에 하나뿐인 나만의 기억저장소"
                    </p>

                    <Form
                        name="normal_login"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        style={{ textAlign: 'left' }}
                    >
                        <Form.Item
                            name="username"
                            rules={[
                                { required: true, message: '이메일을 입력해주세요!' },
                                { type: 'email', message: '유효한 이메일 주소를 입력해주세요!' }
                            ]}
                        >
                            <Input 
                                placeholder="이메일을 입력해 주세요." 
                                allowClear
                                autoComplete="email"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: '비밀번호를 입력해주세요!' },
                                { min: 8, message: '비밀번호는 최소 8자 이상이어야 합니다.' }
                            ]}
                        >
                            <Input.Password
                                placeholder="비밀번호를 입력해 주세요."
                                allowClear
                                autoComplete="current-password"
                                size='large'
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                style={{ 
                                    width: '100%', 
                                    background: '#1747B4', 
                                    border: 'none', 
                                    fontWeight: 'bold' 
                                }} 
                                size="large"
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                로그인
                            </Button>
                        </Form.Item>
                    </Form>
                    <div style={{ marginTop: '20px', fontSize: '14px', color: '#A8A8A8' }}>
                        <Link to="/signup" style={{ marginRight: '10px', color: '#A8A8A8' }}>회원가입</Link> 
                    </div>
                </div>
            </div>
        </App>
    );
};

export default SignInPage;