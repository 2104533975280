import React, { useState, useEffect } from 'react';
import { Modal, Button, AutoComplete, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { searchAutocompleteAPI } from '../../../api/search';
import { AutocompleteItem } from '../../../types/search';
import { togglePin } from '../../../api/pin';
import { Pin } from '../../../types/pin';
import { SearchIdBy } from '../../../api/ChildObj';

interface AddPinModalProps {
    isVisible: boolean;
    onCancel: () => void;
    onAddPin: (newPin: Pin) => Promise<void>;
    userInfo: {
        pins: Pin[];
    };
    refreshUserInfo: () => Promise<void>;
}

const classIdToObjType = (classId: string): string => {
    switch (classId) {
        case 'ObjDoc.Article':
        return 'article';
        case 'ObjDoc.Piece':
        return 'piece';
        case 'ObjDoc.File':
        return 'file';
        default:
        return 'unknown';
    }
};

const AddPinModal: React.FC<AddPinModalProps> = ({ isVisible, onCancel, onAddPin, userInfo, refreshUserInfo }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [options, setOptions] = useState<AutocompleteItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [messageInfo, setMessageInfo] = useState<{ type: 'success' | 'error' | 'info', content: string } | null>(null);

    useEffect(() => {
        if (messageInfo) {
            messageApi[messageInfo.type](messageInfo.content);
            setMessageInfo(null);
        }
    }, [messageInfo, messageApi]);

    const resetState = () => {
        setOptions([]);
        setSelectedItem(null);
        setIsLoading(false);
        setSearchValue('');
    };

    useEffect(() => {
        if (!isVisible) {
            resetState();
        }
    }, [isVisible, refreshUserInfo]);

    const isPinned = (item: any) => {
        return userInfo.pins.some(pin => 
            pin.objId === item._id && 
            pin.objType === classIdToObjType(item._class_id)
        );
    };

    const handleSearch = debounce(async (value: string) => {
        if (value) {
            try {
                const results = await searchAutocompleteAPI(value, 'all');
                setOptions(results);
            } catch (error) {
                setMessageInfo({ type: 'error', content: '자동완성 검색 중 오류가 발생했습니다.' });
            }
        } else {
            setOptions([]);
        }
    }, 300);

    const handleSelect = async (value: string, option: { value: string, label: string }) => {
        const selectedAutoCompleteItem = options.find(item => item.title === value);
        if (!selectedAutoCompleteItem) {
            setMessageInfo({ type: 'error', content: '선택한 항목을 찾을 수 없습니다.' });
            return;
        }

        setIsLoading(true);
        try {
            const item = await SearchIdBy(selectedAutoCompleteItem);
            setSelectedItem(item);
            if (isPinned(item)) {
                setMessageInfo({ type: 'info', content: '이미 핀으로 고정된 게시물입니다.' });
            }
        } catch (error) {
            setMessageInfo({ type: 'error', content: '검색에 실패했습니다.' });
            setSelectedItem(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleConfirm = async () => {
        if (!selectedItem || !selectedItem._class_id || !selectedItem._id) {
            setMessageInfo({ type: 'error', content: '선택한 항목이 없거나 필요한 정보가 부족합니다.' });
            return;
        }

        setIsLoading(true);
        try {
            const objType = classIdToObjType(selectedItem._class_id);
            const objDocId = selectedItem._id;

            const result = await togglePin(objDocId);
            
            if (result) {
                await onAddPin({
                    objType,
                    objId: objDocId,
                    title: selectedItem.title,
                    thumbnail: selectedItem.thumbnail || '',
                    fileType: selectedItem.fileType || ''
                });
                setMessageInfo({ type: 'success', content: '핀이 추가되었습니다.' });
            } else {
                setMessageInfo({ type: 'info', content: '핀 추가에 실패했습니다.' });
            }

            await refreshUserInfo();
            onCancel(); // 성공 후 모달 닫기
        } catch (error) {
            setMessageInfo({ type: 'error', content: '핀 추가 중 오류가 발생했습니다.' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        resetState();
        onCancel();
    };

    const handleClear = () => {
        setSearchValue('');
        setSelectedItem(null);
        setOptions([]);
    };

    return (
        <>
            {contextHolder}
            <Modal
                title="고정할 게시물을 선택해 주세요."
                open={isVisible}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        취소
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        onClick={handleConfirm}
                        disabled={!selectedItem || isLoading || (selectedItem && isPinned(selectedItem))}
                    >
                        {isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} /> : '확인'}
                    </Button>,
                ]}
                centered
                style={{ top: 0 }}
                styles={{
                    body: {
                        maxHeight: 'calc(100vh - 200px)',
                        overflowY: 'auto'
                    }
                }}
            >
                <AutoComplete
                    style={{ width: '100%' }}
                    options={options.map(item => ({ value: item.title, label: item.title }))}
                    onSearch={handleSearch}
                    onSelect={handleSelect}
                    placeholder="제목을 입력하세요"
                    value={searchValue}
                    onChange={setSearchValue}
                    allowClear={true}
                    onClear={() => {
                        setSearchValue('');
                        setSelectedItem(null);
                        setOptions([]);
                    }}
                />
                
            </Modal>
        </>
    );
};

export default AddPinModal;