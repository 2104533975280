import React, { useState, useEffect } from 'react';
import { Card, Tooltip, Dropdown, Menu, message, notification, Button, Popover, Badge } from 'antd';
import { EyeOutlined, StarOutlined, StarFilled, MoreOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Article, Piece,isArticle, isPiece } from '../../../types/ChildObj';
import { setDeletedAt, toggleBookmark, increaseViews, updateTitle, updateKeywords } from '../../../api/ChildObj';
import { useNavigate } from 'react-router-dom';
import { MenuInfo } from 'rc-menu/lib/interface';
import styles from '../../../styles/QuickClipCard.module.css';
import EditModal from '../../ui/modal/EditModal';
// import { checkImageData } from '../../../utils/imageCheck'; 

const { Meta } = Card;
const MAX_DISPLAYED_KEYWORDS = 5;

type ExtendedArticle = Article & { displayedKeywords?: string[] };
type ExtendedPiece = Piece & { displayedKeywords?: string[] };
type ExtendedObj = ExtendedArticle | ExtendedPiece;
interface QuickClipCardProps {
  obj: ExtendedObj;
  onView?: (obj: ExtendedObj) => void
  onViewCountChange: (id: string, count: number) => void;
  onBookmarkToggle: (id: string, bookmarked: boolean) => void;
  onDelete: (id: string) => void;
  onEdit: (id: string, newTitle: string, newKeywords: string[], newDisplayedKeywords: string[]) => void;
}

const QuickClipCard: React.FC<QuickClipCardProps> = ({ 
  obj,
  onView,
  onViewCountChange, 
  onBookmarkToggle, 
  onDelete, 
  onEdit 
}) => {

  
  // useEffect(() => {
  //   if (obj.thumbnail) {
  //     checkImageData(obj.thumbnail);
  //   }
  // }, [obj.thumbnail]);

  const getTypeConfig = () => {
    if (isArticle(obj)) {
      return {
        text: "Article",
        color: "#98bfe3" // 파란색
      };
    }
    return {
      text: "Piece",
      color: "#e09fc1" // 핑크색
    };
  };
  const typeConfig = getTypeConfig();

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [viewCount, setViewCount] = useState(obj.views || 0);
  const [isBookmarked, setIsBookmarked] = useState(obj.bookmark || false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const allKeywords  = obj.keywords || [];
  const displayedKeywords = obj.displayedKeywords || allKeywords.slice(0, MAX_DISPLAYED_KEYWORDS);

  const handleEdit = () => {
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleEditModalSave = async (newTitle: string, newKeywords?: string[], newDisplayedKeywords?: string[]) => {
    if (obj._id) {
      try {
        const titleUpdateSuccess = await updateTitle(obj, newTitle);
        let keywordsUpdateSuccess = true;
        if (newKeywords) {
          keywordsUpdateSuccess = await updateKeywords(obj, newKeywords);
        }

        if (titleUpdateSuccess && keywordsUpdateSuccess) {
          onEdit(obj._id, newTitle, newKeywords || [], newDisplayedKeywords || []);
          message.success('수정이 완료되었습니다.');
        } else {
          message.error('수정 중 오류가 발생했습니다.');
        }
      } catch (error) {
        // console.error('수정 중 오류 발생:', error);
        message.error('수정 중 오류가 발생했습니다.');
      }
    }
    setIsEditModalVisible(false);
  };

  const handleView = async () => {
    if (obj._id) {
        try {
            const newCount = await increaseViews(obj);
            if (newCount !== null) {
                setViewCount(newCount);
                onViewCountChange(obj._id, newCount);
            }
        } catch (error) {
            message.error('조회수 업데이트에 실패했습니다.');
        }
        
        // 외부에서 전달된 onView가 존재하는 경우 호출하여 최근 본 게시물 저장
        if (onView) {
            onView(obj);
        }

        if (isArticle(obj) && obj.linkUrl) {
            window.open(obj.linkUrl, '_blank', 'noopener,noreferrer');
        } else if (isPiece(obj)) {
            navigate(`/quickclip/${obj.supercategory}/${obj.midcategory}/${obj.subcategory || 'no-subcategory'}/${encodeURIComponent(obj.title)}`, {
                state: { piece: obj },
            });
        }
    }
};

  const handleBookmarkToggle = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (obj._id) {
        try {
            const newBookmarkStatus = await toggleBookmark(obj);
            setIsBookmarked(newBookmarkStatus); 
            onBookmarkToggle(obj._id, newBookmarkStatus);
        } catch (error: any) {
          // console.error('북마크 상태 전환 중 오류 발생:', error);
        }
    }
  };

  const handleDelete = async () => {
    if (obj._id && typeof obj._id === 'string') {
      const success = await setDeletedAt(obj);
      if (success) {
        notification.open({
          message: '게시물이 삭제되었습니다',
          description: '휴지통에 30일 동안 보관되며, 그 후에 영구 삭제됩니다.',
          className: 'custom-notification',
          style: {
            borderRadius: 8,
          },
          duration: 4.5,
          btn: (
            <div className="custom-notification-content">
              <div className="custom-notification-button-container">
                <Button 
                  type="primary" 
                  size="small" 
                  onClick={() => navigate('/trash')}
                  className="custom-notification-btn"
                >
                  휴지통 바로가기
                </Button>
              </div>
            </div>
          ),
        });

        onDelete(obj._id);
      } else {
        message.error('휴지통으로 이동 실패');
      }
    } else {
      message.error('유효하지 않은 ID입니다.');
    }
  };

  const menu = (
    <Menu
      onClick={(e: MenuInfo) => e.domEvent.stopPropagation()}
    >
      <Menu.Item
        key="edit"
        onClick={handleEdit}
        icon={<EditOutlined />}
      >
        수정
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={handleDelete}
        icon={<DeleteOutlined />}
      >
        삭제
      </Menu.Item>
    </Menu>
  );

  const SummaryContent = () => (
    <Popover
      content={<div className={styles.fullSummary}>{obj.summary}</div>}
      title="전체 요약"
      trigger="hover"
      mouseEnterDelay={0.5}
    >
      <div className={styles.summary}>{obj.summary}</div>
    </Popover>
  );

  return (
    <>
      <Card
        hoverable
        className={styles.card}
        onClick={handleView} 
        cover={
          <div
            className={styles.imageContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {obj.thumbnail ? (
            <img
              referrerPolicy="no-referrer"
              alt={obj.title}
              src={obj.thumbnail}
              onError={(e) => {
                e.currentTarget.src = "/error_image.png";
              }}
              className={styles.image}
            />
            ) : (
              <div className={`${styles.image} ${styles.placeholder}`} />
            )}
            <div className={`${styles.overlay} ${isHovered ? styles.hovered : ''}`}>
              <div className={styles.keywords}>
                {displayedKeywords.map((keyword, index) => (
                  <span key={index} className={styles.keyword}>
                    #{keyword}
                  </span>
                ))}
              </div>
            </div>
            <div className={`${styles.actions} ${isHovered ? styles.hovered : ''}`}>
              <Dropdown overlay={menu} trigger={['click']}>
                <MoreOutlined 
                  className={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                  }} 
                />
              </Dropdown>
            </div>
          </div>
        }
      >
        <div className={styles.iconContainer}>
          <Tooltip title={viewCount === 0 ? '읽지 않음' : `${viewCount}회 조회`}>
            <EyeOutlined 
              className={styles.icon}
              onClick={(e) => {
                e.stopPropagation();  
                handleView();
              }} 
            />
            {viewCount > 0 && <span style={{ marginLeft: '5px' }}>{viewCount}</span>}
          </Tooltip>
          <Tooltip title={isBookmarked ? '북마크 해제' : '북마크'}>
            {isBookmarked ? (
              <StarFilled 
                onClick={(e) => {
                  e.stopPropagation();
                  handleBookmarkToggle(e);
                }} 
                className={styles.icon}
                style={{ color: '#fadb14' }} 
              />
            ) : (
              <StarOutlined 
                onClick={(e) => {
                  e.stopPropagation();
                  handleBookmarkToggle(e);
                }} 
                className={styles.icon}
              />
            )}
          </Tooltip>
        </div>

        <Meta 
          title={
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginBottom: '8px' }}>
              <Badge 
                count={typeConfig.text}
                style={{ 
                  backgroundColor: typeConfig.color,
                  fontSize: '12px',
                  padding: '0 8px',
                  height: '22px',
                  lineHeight: '22px',
                  borderRadius: '11px',
                  flexShrink: 0
                }} 
              />
              <Popover 
                content={obj.title} 
                trigger="hover"
                placement="top"
                mouseEnterDelay={0.5} 
              >
                <span style={{ 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}>
                  {obj.title}
                </span>
              </Popover>
            </div>
          }
          description={<SummaryContent />}
        />
      </Card>
      <EditModal
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onSave={handleEditModalSave}
        initialTitle={obj.title}
        initialKeywords={allKeywords}
      />
    </>
  );
};

export default QuickClipCard;