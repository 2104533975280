// import React, { useState } from 'react';
// import { Typography } from 'antd';
// import Search from './ui/Search/Search';
// import PinCardList from './ui/card/PinCardList';
// import AddPinModal from './ui/modal/AddPinModal ';
// import { Pin } from '../types/pin';
// import { UserInfo } from '../types/user';

// const { Title } = Typography;

// interface PinComponentProps {
//     userInfo: UserInfo;
//     fetchUserInfo: () => Promise<void>;
// }

// const PinComponent: React.FC<PinComponentProps> = ({ userInfo, fetchUserInfo }) => {
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const displayName = userInfo.nickname || userInfo.email.split('@')[0];

//     const handleAddNew = () => {
//         setIsModalVisible(true);
//     };

//     const handleRemovePin = async (objId: string) => {
//         await fetchUserInfo();
//     };
    
//     const handleAddPin = async (newPin: Pin): Promise<void> => {
//         setIsModalVisible(false);
//         await fetchUserInfo();
//     };

//     return (
//         <div style={{ 
//             display: 'flex', 
//             flexDirection: 'column', 
//             justifyContent: 'center',
//             alignItems: 'center', 
//             width: '100%',
//             height: '100vh',
//             maxWidth: '1200px',
//             margin: '0 auto',
//             padding: '20px',
//             boxSizing: 'border-box',
//         }}>
//             <div style={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 maxHeight: 'calc(100vh - 40px)',
//                 overflow: 'hidden',
//             }}>
//                 <img
//                     src='/images/logo-long.png'
//                     alt='Ceep'
//                     style={{
//                         marginBottom: '20px',
//                         height: '160px',
//                         width: '400px'
//                     }}
//                 />
//                 <Search size="large" style={{ width: '100%', maxWidth: '700px', marginBottom: '40px' }} />
                
//                 <div style={{ 
//                     width: '100%', 
//                     overflow: 'auto', 
//                     maxHeight: 'calc(100vh - 200px)',
//                 }}>
//                     <PinCardList 
//                         pins={userInfo.pins} 
//                         onAddNew={handleAddNew} 
//                         onRemovePin={handleRemovePin}
//                         refreshUserInfo={fetchUserInfo}
//                     />
//                     <AddPinModal 
//                         isVisible={isModalVisible}
//                         onCancel={() => setIsModalVisible(false)}
//                         onAddPin={handleAddPin}
//                         userInfo={userInfo}
//                         refreshUserInfo={fetchUserInfo}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PinComponent;

import React, { useState, useEffect, useCallback } from 'react';
import { Typography, message } from 'antd';
import Search from './ui/Search/Search';
import PinCardList from './ui/card/PinCardList';
import AddPinModal from './ui/modal/AddPinModal ';
import { Pin } from '../types/pin';
import { UserInfo } from '../types/user';
import { getPins } from '../api/pin'; 

const { Title } = Typography;

interface PinComponentProps {
  userInfo: UserInfo;
  fetchUserInfo: () => Promise<void>;
}

const PinComponent: React.FC<PinComponentProps> = ({ userInfo, fetchUserInfo }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pins, setPins] = useState<Pin[]>([]);
  const displayName = userInfo.nickname || userInfo.email.split('@')[0];

  // Fetch pins from API
  const fetchPins = useCallback(async () => {
    try {
      const fetchedPins = await getPins();
      setPins(fetchedPins);
    } catch (error) {
      message.error('핀 데이터를 가져오는 중 오류가 발생했습니다.');
    }
  }, []);

  useEffect(() => {
    fetchPins();
  }, [fetchPins]);

  const handleAddNew = () => {
    setIsModalVisible(true);
  };

  const handleRemovePin = async (objId: string) => {
    await fetchUserInfo();
    await fetchPins(); // Refresh pins after removing
  };

  const handleAddPin = async (newPin: Pin): Promise<void> => {
    setIsModalVisible(false);
    await fetchUserInfo();
    await fetchPins(); // Refresh pins after adding a new pin
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      boxSizing: 'border-box',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: 'calc(100vh - 40px)',
        overflow: 'hidden',
      }}>
        <img
          src='/images/logo-long.png'
          alt='Ceep'
          style={{
            marginBottom: '20px',
            height: '160px',
            width: '400px'
          }}
        />
        <Search size="large" style={{ width: '100%', maxWidth: '700px', marginBottom: '40px' }} />
        
        <div style={{ 
          width: '100%', 
          overflow: 'auto', 
          maxHeight: 'calc(100vh - 200px)',
        }}>
          <PinCardList 
            pins={pins} 
            onAddNew={handleAddNew} 
            onRemovePin={handleRemovePin}
            refreshUserInfo={fetchUserInfo}
          />
          <AddPinModal 
            isVisible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onAddPin={handleAddPin}
            userInfo={userInfo}
            refreshUserInfo={fetchUserInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default PinComponent;

