import React, { useState, useEffect } from 'react';
import { Button, Spin, Empty, Tabs } from 'antd';
import { ClockCircleOutlined, HistoryOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getObjDocs } from '../../api/ChildObj';
import { ObjDoc } from '../../types/ChildObj';
import { getRecentViews, RecentViewData } from '../../utils/localStorage';
import styles from './DailyUploadTimeline.module.css';
import styled from 'styled-components';

const ScrollableDiv = styled.div`
  max-height: 400px;
  overflow-y: auto;

  /* 스크롤바 스타일링 */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const DailyUploadTimeline: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timelineData, setTimelineData] = useState<ObjDoc[]>([]);
  const [recentViews, setRecentViews] = useState<RecentViewData[]>([]);
  const [activeTab, setActiveTab] = useState('1');
  const navigate = useNavigate();

  const dotColorMap: { [key: string]: string } = {
    'ObjDoc.Article': '#98bfe3',
    'ObjDoc.Piece': '#e09fc1',
    'ObjDoc.File': '#87d068',
  };

  const fetchTodayUploads = async () => {
    setLoading(true);
    try {
      const response = await getObjDocs('all', 1, 100);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayItems = response
        .filter(item => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= today;
        })
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      setTimelineData(todayItems);
    } catch (error) {
      console.error('Failed to fetch today uploads:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadRecentViews = () => {
    const views = getRecentViews();
    setRecentViews(views);
  };

  const handleItemClick = (item: ObjDoc | RecentViewData) => {
    const basePath = item._class_id === 'ObjDoc.File' ? '/archive' : '/quickclip';
    const path = item.subcategory
      ? `${basePath}/${item.supercategory}/${item.midcategory}/${item.subcategory}`
      : `${basePath}/${item.supercategory}/${item.midcategory}`;

    navigate(path);
    setIsOpen(false);
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return localDate.toLocaleTimeString('ko-KR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        !target.closest(`.${styles.timelineWrapper}`) &&
        !target.closest(`.${styles.floatingButton}`)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (activeTab === '1') {
        fetchTodayUploads();
      } else {
        loadRecentViews();
      }
    }
  }, [isOpen, activeTab]);

  const renderTimelineItem = (item: ObjDoc | RecentViewData, isRecentView = false) => (
    <div
      key={isRecentView ? item.title : (item as ObjDoc)._id}
      className={styles.timelineItem}
      onClick={() => handleItemClick(item)}
    >
      {!isRecentView && (
        <div className={styles.timeStamp}>{formatTime(item.createdAt)}</div>
      )}
      <div
        className={styles.content}
        style={{
          borderLeft: `3px solid ${dotColorMap[item._class_id]}`,
        }}
      >
        <div className={styles.title}>{item.title}</div>
        <div className={styles.category}>
          {item.supercategory} / {item.midcategory}
          {item.subcategory && ` / ${item.subcategory}`}
        </div>
      </div>
    </div>
  );

  const items = [
    {
      key: '1',
      label: (
        <span style={{ marginLeft: '8px' }}>
          <UploadOutlined />
          최근 업로드
        </span>
      ),
      children: (
        <div className={styles.dropdownBody}>
          {loading ? (
            <div className={styles.centerContent}>
              <Spin />
            </div>
          ) : timelineData.length === 0 ? (
            <Empty description="오늘 업로드된 항목이 없습니다." />
          ) : (
            <div className={styles.timelineContainer}>
              {timelineData.map(item => renderTimelineItem(item))}
            </div>
          )}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <HistoryOutlined />
          최근 본 게시물
        </span>
      ),
      children: (
        <div className={styles.dropdownBody}>
          {recentViews.length === 0 ? (
            <Empty description="최근 본 게시물이 없습니다." />
          ) : (
            <div className={styles.timelineContainer}>
              {recentViews.map(item => renderTimelineItem(item, true))}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.timelineWrapper}>
      <Button
        type="default"
        shape="circle"
        icon={<ClockCircleOutlined style={{ color: '#595959' }} />}
        size="large"
        className={styles.floatingButton}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />

      {isOpen && (
        <ScrollableDiv>
          <div className={styles.dropdownContent} style={{ paddingTop: '3px' }}>
            <Tabs activeKey={activeTab} onChange={setActiveTab} items={items} style={{ marginTop: '8px' }} />
            <div
              className={styles.headerSubtext}
              style={{
                marginTop: '8px',
                textAlign: 'center',
                fontSize: '14px',
                color: '#8c8c8c',
              }}
            >
              (클릭하면 해당 페이지로 이동합니다.)
            </div>
          </div>
        </ScrollableDiv>
      )}
    </div>
  );
};

export default DailyUploadTimeline;
