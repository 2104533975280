import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserInfo } from '../api/user';
import { Button, Spin, Typography, message } from 'antd';
import PinComponent from '../components/PinComponent';
import { UserInfo } from '../types/user';
import axios from 'axios';

const { Title, Text } = Typography;

const HomePage: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchUserInfo = useCallback(async () => {
    setLoading(true);
    try {
      const info = await getUserInfo();
      setUserInfo(info);
      // 사용자 ID를 로컬스토리지에 저장
      if (info._id) {
        localStorage.setItem('_u', info._id);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        message.error('토큰이 만료되었습니다. 다시 로그인해주세요.');
        // 로그아웃 시 로컬스토리지에서 userId 제거
        localStorage.removeItem('_u');
        await logout();
        navigate('/signin');
      } else {
        message.error('사용자 정보를 가져오는 중 오류가 발생했습니다.');
      }
    } finally {
      setLoading(false);
    }
  }, [logout, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserInfo();
    }
  }, [isAuthenticated, fetchUserInfo]);

  const handleLogout = async () => {
    // 로그아웃 시 로컬스토리지에서 userId 제거
    localStorage.removeItem('_u');
    await logout();
    navigate('/');
  };

  const buttonStyle = {
    height: '50px',
    fontSize: '18px',
    borderRadius: '25px',
    padding: '0 30px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease'
  };

  return (
    <>
      {!isAuthenticated ? (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}>
          <Title level={2} style={{ textAlign: 'center' }}>
            클릭 한 번으로 정보 정리<br />
            Ceep에 오신 것을 환영합니다.
          </Title>
          <div style={{ marginTop: '20px' }}>
            <Link to="/signin">
              <Button type="primary" style={{ ...buttonStyle, marginRight: '15px' }}>
                로그인
              </Button>
            </Link>
            <Link to="/signup">
              <Button style={buttonStyle}>회원가입</Button>
            </Link>
          </div>
        </div>
      ) : loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}>
          <Spin size="large" />
        </div>
      ) : userInfo ? (
        <PinComponent userInfo={userInfo} fetchUserInfo={fetchUserInfo} />
      ) : (
        <Text>사용자 정보를 불러오는데 실패했습니다.<br/> 로그인 다시 해주세요.</Text>
      )}
    </>
  );
}

export default HomePage;