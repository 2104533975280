import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { message } from 'antd';

interface RefreshTokenResponse {
    access_token: string;
    refresh_token: string;
    token_type: string;
}

// JWT 디코딩 함수 (토큰 만료 시간 확인용)
export const decodeToken = (token: string) => {
    try {
        const base64Url = token.split('.')[1]; // JWT의 payload 부분 추출
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (error) {
        // console.error('토큰 디코딩 오류:', error);
        return null;
    }
};

// 토큰 만료 시간을 로그로 출력하는 함수
export const logTokenExpiration = () => {
    const token = Cookies.get('accessToken');
    if (token) {
        const decoded = decodeToken(token);
        if (decoded && decoded.exp) {
            const expirationDate = new Date(decoded.exp * 1000); // `exp`는 초 단위이므로 밀리초로 변환
            // console.log('Access Token 만료시간:', expirationDate);
        }
    } else {
        // console.log('토큰이 없습니다.');
    }
};

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
});

// 토큰 갱신 함수
const refreshToken = async (): Promise<RefreshTokenResponse> => {
    try {
        const accessToken = Cookies.get('accessToken');
        const refreshToken = Cookies.get('refreshToken');
        const tokenType = Cookies.get('tokenType') || 'Bearer';

        if (!accessToken || !refreshToken) {
            throw new Error('Access token or refresh token is missing');
        }

        // 리프레시 토큰 요청
        const response = await axios.post<RefreshTokenResponse>(
            `${process.env.REACT_APP_API_URL}api/v1/auth/refresh`,
            { 
                accessToken, 
                refreshToken, 
                tokenType
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }
        );

        const { access_token, refresh_token, token_type } = response.data;

        // 새 토큰을 쿠키에 저장
        Cookies.set('accessToken', access_token, { secure: true, sameSite: 'strict' });
        Cookies.set('refreshToken', refresh_token, { secure: true, sameSite: 'strict' });
        Cookies.set('tokenType', token_type, { secure: true, sameSite: 'strict' });

        // 토큰 갱신 후 만료 시간 로그 출력
        logTokenExpiration();

        return response.data;
    } catch (error) {
        // console.error('Token refresh error:', error);
        message.error('토큰 갱신에 실패했습니다. 다시 로그인하세요.');
        throw new Error('Failed to refresh token');
    }
};

// 요청 인터셉터 - 요청 전에 토큰을 헤더에 추가
axiosInstance.interceptors.request.use(
    (config) => {
        const token = Cookies.get('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터 - 400 오류 발생 시 토큰 갱신 시도
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
        if (error.response?.status === 400 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const { access_token } = await refreshToken();
                // console.log('New Access Token:', access_token); // 새 Access Token 확인
                
                // 새 토큰을 Authorization 헤더에 설정
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
                originalRequest.headers = originalRequest.headers || {};
                originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                
                // 요청을 다시 시도
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                // console.error('토큰 갱신 실패:', refreshError);
                window.dispatchEvent(new Event('logout')); // 로그아웃 이벤트 발생
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;